import { FeedbackNotificationProps } from '@clarke-energia/foton';

export const CLARKE_WHATSAPP = 'https://api.whatsapp.com/send?phone=5511934090834';

export const StateSymbols = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO',
  'DF',
];

export interface INotificationFeedbackContent {
  label: string;
  message: string;
  kind: FeedbackNotificationProps['kind'];
  onCloseNotification?: () => void;
}

export const MigrationNotificationFeedbackContents: Record<string, INotificationFeedbackContent> = {
  MIGRATION_REPORT_SYNC_SUCCESS: {
    label: 'Sucesso',
    message: 'Sincronização realizada com sucesso',
    kind: 'primary',
  },
  MIGRATION_REPORT_SYNC_ERROR: {
    label: 'Erro',
    message: 'Ocorreu um erro na sincronização. Revise os dados do grupo no Asana e tente novamente.',
    kind: 'error',
  },
  MIGRATION_REPORT_SYNC_IN_PROGRESS: {
    label: 'Aguarde',
    message: 'A sincronização está em progresso...',
    kind: 'neutral',
  },
  EMAIL_SENDING_SUCCESS: {
    label: 'Sucesso',
    message: 'Email enviado com sucesso',
    kind: 'primary',
  },
  EMAIL_SENDING_ERROR: {
    label: 'Erro',
    message: 'Erro ao tentar enviar email',
    kind: 'error',
  },
  EMAIL_OPEN_ERROR: {
    label: 'Erro',
    message: 'Não é possível enviar e-mail, pois não houve sincronização com o asana',
    kind: 'error',
  },
};

export const EconomyNotificationFeedbackContents: Record<string, INotificationFeedbackContent> = {
  CREATING_ECONOMY_REPORT: {
    label: 'Sucesso',
    message: 'Relatório criado com sucesso!',
    kind: 'primary',
  },
  CREATING_ECONOMY_REPORT_ERROR: {
    label: 'Erro',
    message: 'Houve um erro ao criar o relatório. Revise os dados e tente novamente.',
    kind: 'error',
  },
  UPDATING_ECONOMY_REPORT: {
    label: 'Sucesso',
    message: 'Relatório editado com sucesso!',
    kind: 'primary',
  },
  UPDATING_ECONOMY_REPORT_ERROR: {
    label: 'Erro',
    message: 'Houve um erro ao editar o relatório. Revise os dados e tente novamente.',
    kind: 'error',
  },
  CREATING_GROUP_ECONOMY_REPORT: {
    label: 'Sucesso',
    message: 'Relatório do grupo criado com sucesso!',
    kind: 'primary',
  },
  UPDATING_GROUP_ECONOMY_REPORT: {
    label: 'Sucesso',
    message: 'Relatório do grupo editado com sucesso!',
    kind: 'primary',
  },
  UPDATING_ECONOMY_REPORTS_STATUS: {
    label: 'Publicando',
    message: 'Os relatórios estão sendo publicados...',
    kind: 'neutral',
  },
  SUCCESS_ON_UPDATE_ECONOMY_REPORTS_STATUS: {
    label: 'Sucesso',
    message: 'Relatórios publicados com sucesso',
    kind: 'primary',
  },
  ERROR_ON_UPDATE_ECONOMY_REPORTS_STATUS: {
    label: 'Erro',
    message: 'Houve um erro ao publicar relatórios.',
    kind: 'error',
  },
  NO_ECONOMY_REPORTS_TO_SEND: {
    label: 'Concluído',
    message: 'Todos os relatórios já foram publicados.',
    kind: 'neutral',
  },
  NOTIFYING_ECONOMY_REPORT: {
    label: 'Enviando email',
    message: 'O email esta sendo enviado...',
    kind: 'neutral',
  },
  SUCCESS_ON_NOTIFY_ECONOMY_REPORT: {
    label: 'Sucesso',
    message: 'Email enviado com sucesso',
    kind: 'primary',
  },
  ERROR_ON_NOTIFYING_ECONOMY_REPORT: {
    label: 'Erro',
    message: 'Houve um erro ao enviar email.',
    kind: 'error',
  },
};

export const CommercialGroupNotificationFeedbackContents: Record<string, INotificationFeedbackContent> = {
  CREATING_COMMERCIAL_GROUP_SUCCESS: {
    label: 'Sucesso',
    message: 'Grupo Comercial criado com sucesso!',
    kind: 'primary',
  },
  CREATING_COMMERCIAL_GROUP_ERROR: {
    label: 'Erro',
    message: 'Ocorreu um erro na criação do Grupo Comercial. Revise os dados e tente novamente.',
    kind: 'error',
  },
  CREATING_DRIVE_COMMERCIAL_GROUP_ERROR: {
    label: 'Erro',
    message: 'Ocorreu um erro ao adicionar pasta do Google Drive. Revise os dados e tente novamente.',
    kind: 'error',
  },
  UPDATING_COMMERCIAL_GROUP_SUCCESS: {
    label: 'Sucesso',
    message: 'Grupo Comercial atualizado com sucesso!',
    kind: 'primary',
  },
  UPDATING_COMMERCIAL_GROUP_ERROR: {
    label: 'Erro',
    message: 'Ocorreu um erro na atualização do Grupo Comercial. Revise os dados e tente novamente.',
    kind: 'error',
  },
  UPDATING_DRIVE_COMMERCIAL_GROUP_ERROR: {
    label: 'Erro',
    message: 'Ocorreu um erro na atualização da pasta do google Drive do grupo. Revise os dados e tente novamente.',
    kind: 'error',
  },
  DELETING_COMMERCIAL_GROUP_SUCCESS: {
    label: 'Sucesso',
    message: 'Grupo Comercial excluído com sucesso!',
    kind: 'primary',
  },
  DELETING_COMMERCIAL_GROUP_ERROR: {
    label: 'Erro',
    message: 'Ocorreu um erro na exclusão do Grupo Comercial.',
    kind: 'error',
  },
};

export const UnitNotificationFeedbackContents: Record<string, INotificationFeedbackContent> = {
  CREATING_UNIT_SUCCESS: {
    label: 'Sucesso',
    message: 'Unidade criada com sucesso!',
    kind: 'primary',
  },
  CREATING_UNIT_ERROR: {
    label: 'Erro',
    message: 'Ocorreu um erro na criação da Unidade. Revise os dados e tente novamente.',
    kind: 'error',
  },
  UPDATING_UNIT_SUCCESS: {
    label: 'Sucesso',
    message: 'Unidade atualizada com sucesso!',
    kind: 'primary',
  },
  UPDATING_UNIT_ERROR: {
    label: 'Erro',
    message: 'Ocorreu um erro na atualização da Unidade. Revise os dados e tente novamente.',
    kind: 'error',
  },
  DELETING_UNIT_SUCCESS: {
    label: 'Sucesso',
    message: 'Unidade excluída com sucesso!',
    kind: 'primary',
  },
  DELETING_UNIT_ERROR: {
    label: 'Erro',
    message: 'Ocorreu um erro na exclusão da Unidade.',
    kind: 'error',
  },
};

export const BindUserNotificationFeedbackContents: Record<string, INotificationFeedbackContent> = {
  BIDING_USER_SUCCESS: {
    label: 'Sucesso',
    message: 'Usuário vinculado com sucesso!',
    kind: 'primary',
  },
  BIDING_USER_ERROR: {
    label: 'Erro',
    message: 'Ocorreu um erro na vinculação do usuário. Revise os dados e tente novamente.',
    kind: 'error',
  },
  DELETING_USER_SUCCESS: {
    label: 'Sucesso',
    message: 'Usuário excluído com sucesso!',
    kind: 'primary',
  },
  DELETING_USER_ERROR: {
    label: 'Erro',
    message: 'Ocorreu um erro na exclusão do usuário.',
    kind: 'error',
  },
};

export const TraderNotificationFeedbackContents: Record<string, INotificationFeedbackContent> = {
  CREATING_TRADER_SUCCESS: {
    label: 'Sucesso',
    message: 'Fornecedora cadastrada com sucesso!',
    kind: 'primary',
  },
  CREATING_TRADER_ERROR: {
    label: 'Erro',
    message: 'Ocorreu um erro no cadastro da Fornecedora. Revise os dados e tente novamente.',
    kind: 'error',
  },
  UPDATING_TRADER_SUCCESS: {
    label: 'Sucesso',
    message: 'Fornecedora atualizada com sucesso!',
    kind: 'primary',
  },
  UPDATING_TRADER_ERROR: {
    label: 'Erro',
    message: 'Ocorreu um erro na atualização da Fornecedora. Revise os dados e tente novamente.',
    kind: 'error',
  },
  DELETING_TRADER_SUCCESS: {
    label: 'Sucesso',
    message: 'Fornecedora excluída com sucesso!',
    kind: 'primary',
  },
  DELETING_TRADER_ERROR: {
    label: 'Erro',
    message: 'Ocorreu um erro na exclusão da Fornecedora.',
    kind: 'error',
  },
};

export const ClarkeContractNotificationFeedbackContents: Record<string, INotificationFeedbackContent> = {
  CREATING_CLARKE_CONTRACT_SUCCESS: {
    label: 'Sucesso',
    message: 'Contrato Clarke cadastrado com sucesso!',
    kind: 'primary',
  },
  CREATING_CLARKE_CONTRACT_ERROR: {
    label: 'Erro',
    message: 'Ocorreu um erro no cadastro do Contrato Clarke. Revise os dados e tente novamente.',
    kind: 'error',
  },
  UPDATING_CLARKE_CONTRACT_SUCCESS: {
    label: 'Sucesso',
    message: 'Contrato Clarke atualizado com sucesso!',
    kind: 'primary',
  },
  UPDATING_CLARKE_CONTRACT_ERROR: {
    label: 'Erro',
    message: 'Ocorreu um erro na atualização do Contrato Clarke. Revise os dados e tente novamente.',
    kind: 'error',
  },
  DELETING_CLARKE_CONTRACT_SUCCESS: {
    label: 'Sucesso',
    message: 'Contrato Clarke excluído com sucesso!',
    kind: 'primary',
  },
  DELETING_CLARKE_CONTRACT_ERROR: {
    label: 'Erro',
    message: 'Ocorreu um erro na exclusão do Contrato Clarke.',
    kind: 'error',
  },
  DELETING_CLARKE_CONTRACT_ATTACHMENT_SUCCESS: {
    label: 'Sucesso',
    message: 'Anexo excluído com sucesso!',
    kind: 'primary',
  },
  DELETING_CLARKE_CONTRACT_ATTACHMENT_ERROR: {
    label: 'Erro',
    message: 'Ocorreu um erro na exclusão do anexo.',
    kind: 'error',
  },
};

export const CCEEProfileNotificationFeedbackContents: Record<string, INotificationFeedbackContent> = {
  CREATING_CCEE_PROFILE_SUCCESS: {
    label: 'Sucesso',
    message: 'Perfil CCEE cadastrado com sucesso!',
    kind: 'primary',
  },
  CREATING_CCEE_PROFILE_ERROR: {
    label: 'Erro',
    message: 'Ocorreu um erro no cadastro do Perfil CCEE. Revise os dados e tente novamente.',
    kind: 'error',
  },
  UPDATING_CCEE_PROFILE_SUCCESS: {
    label: 'Sucesso',
    message: 'Perfil CCEE atualizado com sucesso!',
    kind: 'primary',
  },
  UPDATING_CCEE_PROFILE_ERROR: {
    label: 'Erro',
    message: 'Ocorreu um erro na atualização do Perfil CCEE. Revise os dados e tente novamente.',
    kind: 'error',
  },
  DELETING_CCEE_PROFILE_SUCCESS: {
    label: 'Sucesso',
    message: 'Perfil CCEE excluído com sucesso!',
    kind: 'primary',
  },
  DELETING_CCEE_PROFILE_ERROR: {
    label: 'Erro',
    message: 'Ocorreu um erro na exclusão do Perfil CCEE.',
    kind: 'error',
  },
};

export const CCEEContractNotificationFeedbackContents: Record<string, INotificationFeedbackContent> = {
  CREATING_CCEE_CONTRACT_SUCCESS: {
    label: 'Sucesso',
    message: 'Contrato CCEE cadastrado com sucesso!',
    kind: 'primary',
  },
  CREATING_CCEE_CONTRACT_ERROR: {
    label: 'Erro',
    message: 'Ocorreu um erro no cadastro do Contrato CCEE. Revise os dados e tente novamente.',
    kind: 'error',
  },
  UPDATING_CCEE_CONTRACT_SUCCESS: {
    label: 'Sucesso',
    message: 'Contrato CCEE atualizado com sucesso!',
    kind: 'primary',
  },
  UPDATING_CCEE_CONTRACT_ERROR: {
    label: 'Erro',
    message: 'Ocorreu um erro na atualização do Contrato CCEE. Revise os dados e tente novamente.',
    kind: 'error',
  },
  DELETING_CCEE_CONTRACT_SUCCESS: {
    label: 'Sucesso',
    message: 'Contrato CCEE excluído com sucesso!',
    kind: 'primary',
  },
  DELETING_CCEE_CONTRACT_ERROR: {
    label: 'Erro',
    message: 'Ocorreu um erro na exclusão do Contrato CCEE.',
    kind: 'error',
  },
};

export const KickOffNotificationFeedbackContents: Record<string, INotificationFeedbackContent> = {
  QUIZ_EMAIL_SEND_SUCCESS: {
    kind: 'primary',
    label: 'Sucesso',
    message: 'Email enviado.',
  },
  QUIZ_EMAIL_SEND_IN_PROGRESS: {
    kind: 'neutral',
    label: 'Aguarde',
    message: 'Envio de email em andamento.',
  },
  QUIZ_EMAIL_SEND_ERROR: {
    kind: 'error',
    label: 'Erro',
    message: 'O email não pôde ser enviado.',
  },
  QUIZ_AVAILABILITY_UPDATE_SUCCESS: {
    kind: 'primary',
    label: 'Sucesso',
    message: 'Questionário atualizado.',
  },
  QUIZ_AVAILABILITY_UPDATE_IN_PROGRESS: {
    kind: 'neutral',
    label: 'Aguarde',
    message: 'Atualizando questionário.',
  },
  QUIZ_AVAILABILITY_UPDATE_ERROR: {
    kind: 'error',
    label: 'Erro',
    message: 'Questionário não pôde ser atualizado.',
  },
  DIAGNOSIS_UPDATE_SUCCESS: {
    kind: 'primary',
    label: 'Sucesso',
    message: 'Edições salvas.',
  },
  DIAGNOSIS_UPDATE_ERROR: {
    kind: 'error',
    label: 'Erro',
    message: 'As edições não puderam ser salvas.',
  },
  KICK_OFF_CREATION_SUCCESS: {
    kind: 'primary',
    label: 'Sucesso',
    message: 'Processo de Kick Off iniciado.',
  },
  KICK_OFF_CREATION_IN_PROGRESS: {
    kind: 'neutral',
    label: 'Aguarde',
    message: 'Estamos criando o processo de Kick Off.',
  },
  KICK_OFF_CREATION_ERROR: {
    kind: 'error',
    label: 'Erro',
    message: 'O processo de Kick Off não pôde ser criado.',
  },
};

export const CreateRoleCategoryUserNotificationFeedbackContents: Record<string, INotificationFeedbackContent> = {
  ROLE_USER_SUCCESS: {
    label: 'Sucesso',
    message: 'Categorias adicionadas com sucesso!',
    kind: 'primary',
  },
  ROLE_USER_ERROR: {
    label: 'Erro',
    message: 'Ocorreu um erro ao adicionar categoria ao usuário. Revise os dados e tente novamente.',
    kind: 'error',
  },
};

export const clarkeInfos = [
  `Clarke Energia\ncnpj 35.472.171/0001-06`,
  `Rua dos Pinheiros, 610 – SP\noperacoes@clarke.com.br`,
];

export const PROINFA_LIST_ITEMS_BY_PAGE = 15;
