import React, { useState, useMemo } from 'react';
import moment from 'moment';
import { Button, Select } from '@clarke-energia/foton';

import { MonthTranslationMapper } from '@utils/translators/proposal';
import { FinancialEventsFilters } from '@contexts/financial-events';

export interface IPopUpList {
  filters: FinancialEventsFilters | undefined;
  show?: boolean;
  onChangeMonth: (month: string) => void;
  onChangeYear: (year: string) => void;
  setCurrentMonthYear: () => void;
  setPreviousMonthYear: () => void;
}

export const PopUpSetPeriod = ({
  filters,
  onChangeMonth,
  onChangeYear,
  show,
  setCurrentMonthYear,
  setPreviousMonthYear,
}: IPopUpList) => {
  const [yearsRange, setYearsRange] = useState<number[]>([]);

  useMemo(() => {
    const currentYear = moment().year();
    const localYears = [];
    let startYear = 2020;
    while (startYear <= currentYear) {
      localYears.push(startYear++);
    }
    setYearsRange(localYears);
  }, []);

  return (
    <div
      className={`flex flex-col gap-5 absolute shadow-3 p-5 bg-white z-10 border-black border-[1px] rounded-medium top-9 w-[8rem] md:w-[15rem] ${
        !show && 'hidden'
      }`}
    >
      <Select
        label="Mês"
        options={Object.values(MonthTranslationMapper).map((month, index) => ({
          display: `${month.charAt(0).toUpperCase()}${month.slice(1)}`,
          value: (index + 1).toString(),
        }))}
        value={filters?.month?.toString() || 'Selecione'}
        onChange={(e) => onChangeMonth(e.target.value)}
      />
      <Select
        label="Ano"
        options={yearsRange.map((year) => ({
          display: `${year}`,
          value: `${year}`,
        }))}
        value={filters?.year?.toString() || 'Selecione'}
        onChange={(e) => onChangeYear(e.target.value)}
      />
      <div className="flex flex-col gap-3 my-4">
        <Button
          onClick={setCurrentMonthYear}
          kind="primary"
          size="small"
          label="Mês atual"
          className="self-center mt-4 w-full"
        />
        <Button
          onClick={setPreviousMonthYear}
          kind="secondary"
          size="small"
          label="Mês anterior"
          className="self-center mt-4 w-full"
        />
      </div>
    </div>
  );
};
