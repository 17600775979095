import React, { useState } from 'react';
import { ButtonInline } from '@clarke-energia/foton';

import { CommercialGroup } from '@contexts/commercial-group';
import useEnergyAuditReports from '@hooks/use-energy-audit-report';
import { useNotification } from '@hooks/use-notification';

import EnergyAuditReportsTable from '@components/atoms/energy-audit-report/table';
import FiltersBar from '@components/atoms/energy-audit/filters';
import { generalAuditsFeedbackContent } from '@pages/energy-audit/static-data';

export type SelectedFilter = {
  value: string;
  label: string;
};

const EnergyAuditReportList: React.FC<{ referenceDate: string; groups: CommercialGroup[] }> = ({
  referenceDate,
  groups,
}) => {
  const { getAllEnergyReports, energyAuditReports, loading, handleDownloadExcel } = useEnergyAuditReports();
  const { setNotificationResponse } = useNotification();

  const [page, setPage] = useState<number>(1);
  const [selectedGroup, setSelectedGroup] = useState<SelectedFilter | null>(null);
  const [showFilterOptions, setShowFilterOptions] = useState<'GROUP' | null>(null);

  React.useEffect(() => {
    getAllEnergyReports({ page, date: referenceDate, groupId: selectedGroup?.value });
  }, [selectedGroup?.value, page, referenceDate]);

  const downloadExcel = React.useCallback(async () => {
    setNotificationResponse(generalAuditsFeedbackContent['EXCEL_DOWNLOAD_IN_PROGRESS']);
    const feedbackResponse = await handleDownloadExcel({ referenceDate });
    setNotificationResponse(generalAuditsFeedbackContent[feedbackResponse]);
  }, [referenceDate]);

  const groupFilter = React.useMemo(
    () => ({
      label: selectedGroup?.label ?? 'Grupo comercial',
      options: groups.length ? groups.map(({ id, name }) => ({ label: name, value: id })) : [],
      selectedFilter: selectedGroup,
      setSelectedFilter: setSelectedGroup,
      handleFilterVisibility: () => {
        if (showFilterOptions === 'GROUP') return setShowFilterOptions(null);
        setShowFilterOptions('GROUP');
      },
      showOptions: showFilterOptions === 'GROUP',
    }),
    [showFilterOptions, selectedGroup],
  );

  return (
    <>
      <div className="justify-between lg:flex">
        <FiltersBar
          btnsFilters={[groupFilter]}
          clearOptions={() => {
            setSelectedGroup(null);
            setShowFilterOptions(null);
          }}
        />

        <ButtonInline
          label="Exportar EXCEL"
          icon="ArrowUpRightIcon"
          type="button"
          kind="secondary"
          onClick={() => downloadExcel()}
          className="w-full lg:w-fit"
        />
      </div>
      <EnergyAuditReportsTable
        loading={loading}
        tableData={energyAuditReports.reports}
        total={energyAuditReports.total}
        currentPage={energyAuditReports.page}
        itemPerpage={energyAuditReports.limit}
        setCurrentPage={setPage}
      />
    </>
  );
};

export default EnergyAuditReportList;
