import { useContextSelector } from 'use-context-selector';
import { EnergyAuditsContext, EnergyAuditsContextType } from '@contexts/energy-audit';

const useEnergyAudits = (): EnergyAuditsContextType => {
  const energyAudits = useContextSelector(EnergyAuditsContext, (EnergyAudits) => EnergyAudits.energyAudits);
  const getAllEnergyAuditsHandler = useContextSelector(
    EnergyAuditsContext,
    (EnergyAudits) => EnergyAudits.getAllEnergyAuditsHandler,
  );
  const openNotificationFeedBack = useContextSelector(
    EnergyAuditsContext,
    (EnergyAudits) => EnergyAudits.openNotificationFeedBack,
  );
  const setOpenNotificationFeedBack = useContextSelector(
    EnergyAuditsContext,
    (EnergyAudits) => EnergyAudits.setOpenNotificationFeedBack,
  );
  const getEnergyAuditByIdHandler = useContextSelector(
    EnergyAuditsContext,
    (EnergyAudits) => EnergyAudits.getEnergyAuditByIdHandler,
  );
  const setEnergyAuditId = useContextSelector(EnergyAuditsContext, (EnergyAudits) => EnergyAudits.setEnergyAuditId);
  const energyAuditById = useContextSelector(EnergyAuditsContext, (EnergyAudits) => EnergyAudits.energyAuditById);
  const lastSync = useContextSelector(EnergyAuditsContext, (EnergyAudits) => EnergyAudits.lastSync);
  const loading = useContextSelector(EnergyAuditsContext, (EnergyAudits) => EnergyAudits.loading);
  const prepareEnergyAuditToBeSentHandler = useContextSelector(
    EnergyAuditsContext,
    (EnergyAudits) => EnergyAudits.prepareEnergyAuditToBeSentHandler,
  );
  const openPeriodSelector = useContextSelector(EnergyAuditsContext, (EnergyAudits) => EnergyAudits.openPeriodSelector);
  const setOpenPeriodSelector = useContextSelector(
    EnergyAuditsContext,
    (EnergyAudits) => EnergyAudits.setOpenPeriodSelector,
  );
  const energyAuditsPeriods = useContextSelector(
    EnergyAuditsContext,
    (EnergyAudits) => EnergyAudits.energyAuditsPeriods,
  );
  const setEnergyAuditsPeriods = useContextSelector(
    EnergyAuditsContext,
    (EnergyAudits) => EnergyAudits.setEnergyAuditsPeriods,
  );
  const selectedEnergyAudit = useContextSelector(
    EnergyAuditsContext,
    (EnergyAudits) => EnergyAudits.selectedEnergyAudit,
  );
  const setSelectedEnergyAudit = useContextSelector(
    EnergyAuditsContext,
    (EnergyAudits) => EnergyAudits.setSelectedEnergyAudit,
  );
  const rawDate = useContextSelector(EnergyAuditsContext, (EnergyAudits) => EnergyAudits.rawDate);
  const openNotificationFeedBackSync = useContextSelector(
    EnergyAuditsContext,
    (EnergyAudits) => EnergyAudits.openNotificationFeedBackSync,
  );
  const setOpenNotificationFeedBackSync = useContextSelector(
    EnergyAuditsContext,
    (EnergyAudits) => EnergyAudits.setOpenNotificationFeedBackSync,
  );

  const getEnergyAuditByEnergyContractIdStatusAndDateHandler = useContextSelector(
    EnergyAuditsContext,
    (EnergyAudits) => EnergyAudits.getEnergyAuditByEnergyContractIdStatusAndDateHandler,
  );

  const groupPeriods = useContextSelector(EnergyAuditsContext, (EnergyAudits) => EnergyAudits.groupPeriods);

  const getEmailDataHandler = useContextSelector(
    EnergyAuditsContext,
    (EnergyAudits) => EnergyAudits.getEmailDataHandler,
  );

  const loadingEmailData = useContextSelector(EnergyAuditsContext, (EnergyAudits) => EnergyAudits.loadingEmailData);
  const getScdeListMeasurementHandler = useContextSelector(
    EnergyAuditsContext,
    (EnergyAudits) => EnergyAudits.getScdeListMeasurementHandler,
  );
  const scdeData = useContextSelector(EnergyAuditsContext, (EnergyAudits) => EnergyAudits.scdeData);
  const createEnergyAuditUploadHandler = useContextSelector(
    EnergyAuditsContext,
    (EnergyAudits) => EnergyAudits.createEnergyAuditUploadHandler,
  );

  return {
    energyAudits,
    getAllEnergyAuditsHandler,
    getEnergyAuditByIdHandler,
    setEnergyAuditId,
    energyAuditById,
    openNotificationFeedBack,
    lastSync,
    setOpenNotificationFeedBack,
    openNotificationFeedBackSync,
    setOpenNotificationFeedBackSync,
    loading,
    prepareEnergyAuditToBeSentHandler,
    openPeriodSelector,
    setOpenPeriodSelector,
    energyAuditsPeriods,
    setEnergyAuditsPeriods,
    selectedEnergyAudit,
    setSelectedEnergyAudit,
    rawDate,
    groupPeriods,
    getEnergyAuditByEnergyContractIdStatusAndDateHandler,
    getEmailDataHandler,
    loadingEmailData,
    getScdeListMeasurementHandler,
    scdeData,
    createEnergyAuditUploadHandler,
  };
};

export default useEnergyAudits;
