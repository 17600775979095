import axios from 'axios';
import configuration from '../../config';
import { DownloadExcelPayload } from '@contexts/energy-audit-consumptions';
import downloadExcelParser, { downloadExcelToEnergyAuditParser } from './parser';
import { FileToUpload } from '@contexts/energy-audit';

export const cceeApi = axios.create({
  baseURL: configuration.CCEE_API_HOST,
});

export const donwloadExcel = (data: DownloadExcelPayload, accessToken: string) => {
  const formData = downloadExcelParser(data);
  return cceeApi.post('/api/v1/unit_measurements_excel/', formData, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    responseType: 'arraybuffer',
  });
};

export function createEnergyAuditUpload(data: FileToUpload, accessToken: string) {
  const formData = downloadExcelToEnergyAuditParser(data);
  return cceeApi.post('/api/v1/scde_measurements_file/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
    },
  });
}
