import React from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';

import { Accordion, Button } from '@clarke-energia/foton';
import GoogleDriveImg from '@assets/google_drive_id.png';

import {
  ComercialGroupTypeEnum,
  GroupFormPayload,
  EMPTY_GROUP_PAYLOAD,
  CommercialGroupPayload,
} from '@contexts/commercial-group/types';
import useCommercialGroups from '@hooks/use-commercial-group';

import { FormField, GroupSelectInputFormField, SelectFormField } from '@components/atoms/form';

import './style.css';

const comercialGroupTypeOptions = [
  { value: ComercialGroupTypeEnum.RETAIL, optionLabel: 'Varejo' },
  { value: ComercialGroupTypeEnum.MANAGEMENT_RETAIL, optionLabel: 'Gestão (varejista)' },
  { value: ComercialGroupTypeEnum.MANAGEMENT_WHOLESALE, optionLabel: 'Gestão (atacadista)' },
  { value: ComercialGroupTypeEnum.LEAD, optionLabel: 'Lead' },
  { value: ComercialGroupTypeEnum.RETAIL_TRANSFER_CONTRACTS, optionLabel: 'Varejo (tombamento)' },
];

interface IForm {
  dataToUpdate: GroupFormPayload | null;
  onFormSuccess: () => void;
  onFormFail: (value: boolean) => void;
  setOpenCreateCommercialGroup: (open: boolean) => void;
}

function FormCreateCommercialGroup({ onFormSuccess, onFormFail, dataToUpdate, setOpenCreateCommercialGroup }: IForm) {
  const { createCommercialGroupHandler, updateCommercialGroupHandler, upsertGroupDriveFolderHandler, loading } =
    useCommercialGroups();

  const methods = useForm<GroupFormPayload>({
    defaultValues: dataToUpdate ? dataToUpdate : EMPTY_GROUP_PAYLOAD,
  });
  const docType = methods.watch('docType');

  const docNumberFormat = {
    placeholder: docType === 'CNPJ' ? '00.000.000/0000-00' : '000.000.000-00',
    format: docType === 'CNPJ' ? '##.###.###/####-##' : '###.###.###-##',
    mask: '_',
    allowNegative: false,
    fixedDecimalScale: false,
    decimalSeparator: undefined,
    thousandSeparator: undefined,
    decimalScale: 0,
    isNumericString: true,
  };

  const docOptions = [
    { value: 'CNPJ', label: 'CNPJ' },
    { value: 'CPF', label: 'CPF' },
  ];

  const preventFormSubmission = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  const handleError = (driveErrorMessage: boolean) => {
    onFormFail(driveErrorMessage);
    setOpenCreateCommercialGroup(false);
  };

  const handleGroupResponse = (groupId: string, id: string, targetFolderId: string) => {
    return upsertGroupDriveFolderHandler({
      id,
      commercialGroupId: groupId,
      targetFolderId,
    })
      .then(() => onFormSuccess())
      .catch(() => handleError(true));
  };

  const onSubmit: SubmitHandler<GroupFormPayload> = async (values) => {
    const { targetFolderId, folder, ...restValues } = values;

    const dataHandler = dataToUpdate ? updateCommercialGroupHandler : createCommercialGroupHandler;

    return dataHandler({
      ...(restValues as CommercialGroupPayload),
    })
      .then(({ data: { createCommercialGroup } }) => {
        const groupId = dataToUpdate ? dataToUpdate.id : createCommercialGroup.id;
        if (targetFolderId) {
          handleGroupResponse(groupId, targetFolderId, folder);
          return;
        }
        onFormSuccess();
      })
      .catch(() => handleError(false));
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="flex flex-col gap-3 pt-4 -mb-8">
        <FormField<GroupFormPayload>
          id="name"
          label="Nome"
          field="name"
          placeholder="Cliente Exemplo"
          options={{ required: { value: true, message: 'O nome é obrigatório' } }}
        />
        <FormField<GroupFormPayload>
          id="legalName"
          label="Razão Social"
          field="legalName"
          placeholder="Cliente Exemplo LTDA"
        />
        <GroupSelectInputFormField<GroupFormPayload>
          firstField="docNumber"
          secondField="docType"
          id="docNumber"
          selectOptions={docOptions}
          label="Documento"
          type="number"
          formatProps={docNumberFormat}
          options={{
            required: { value: true, message: 'O documento é obrigatório' },
            validate: (value) => {
              if (docType === 'CNPJ' && value?.length !== 14) {
                return 'O cnpj deve conter 14 dígitos.';
              }
              if (docType === 'CPF' && value?.length !== 11) {
                return 'O cpf deve conter 11 dígitos.';
              }
            },
            onChange: (e) => {
              const value = e.target.value;
              if (value === 'CNPJ' || value === 'CPF') methods.setValue('docType', value);
            },
          }}
        />

        <SelectFormField<GroupFormPayload>
          label="Tipo de cliente"
          field="type"
          id="comercialGroupType"
          inputOptions={comercialGroupTypeOptions}
          options={{ required: { value: true, message: 'Campo é obrigatório' } }}
        />

        <>
          <FormField<GroupFormPayload> id="folder" label="ID pasta do Google Drive" field="folder" />
          <div onClick={preventFormSubmission}>
            <Accordion label="Onde encontrar esse ID?">
              <img src={GoogleDriveImg} alt="google drive img" />
            </Accordion>
          </div>
        </>

        <Button
          label={`${dataToUpdate ? 'Atualizar' : 'Criar'} cliente`}
          kind={'primary'}
          type="submit"
          loading={loading}
          className="mt-5"
        />
      </form>
    </FormProvider>
  );
}

export default FormCreateCommercialGroup;
