import React, { useState, useRef, useEffect } from 'react';
import { Button } from '@clarke-energia/foton';

import { FinancialEventsFilters } from '@contexts/financial-events';

import { FinancialEventsListDateFilter } from '@components/atoms/financial-events/list/date-filter';
import { FinancialEventsListCommercialGroupFilter } from '@components/atoms/financial-events/list/commercial-group-filter';
import { FinancialEventsListStatusFilter } from '@components/atoms/financial-events/list/status-filter';

interface FinancialEventsFilterBarProps {
  title: string;
  filters: FinancialEventsFilters | undefined;
  setFilters: (value: React.SetStateAction<FinancialEventsFilters | undefined>) => void;
  someFilterUsed: boolean;
  setSomeFilterUsed: (value: React.SetStateAction<boolean>) => void;
}

const FinancialEventsFilterBar: React.FC<FinancialEventsFilterBarProps> = ({
  title,
  setFilters,
  filters,
  someFilterUsed,
  setSomeFilterUsed,
}) => {
  const [openGroupFilterPopUp, setOpenGroupFilterPopUp] = useState<boolean>(false);
  const [openDateFilterPopUp, setOpenDateFilterPopUp] = useState<boolean>(false);
  const [openStatusFilterPopUp, setOpenStatusFilterPopUp] = useState<boolean>(false);

  const closePopUps = () => {
    setOpenGroupFilterPopUp(false);
    setOpenDateFilterPopUp(false);
    setOpenStatusFilterPopUp(false);
  };

  const clearFilters = () => {
    setFilters(undefined);
    setSomeFilterUsed(false);
    closePopUps();
  };

  const dateFilterPopupRef = useRef<HTMLDivElement>(null);
  const groupFilterPopupRef = useRef<HTMLDivElement>(null);
  const statusFilterPopupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent, ref: React.RefObject<HTMLDivElement>) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        ref === dateFilterPopupRef && setOpenDateFilterPopUp(false);
        ref === groupFilterPopupRef && setOpenGroupFilterPopUp(false);
        ref === statusFilterPopupRef && setOpenStatusFilterPopUp(false);
      }
    };

    document.addEventListener('click', (event) => handleOutsideClick(event, dateFilterPopupRef));
    document.addEventListener('click', (event) => handleOutsideClick(event, groupFilterPopupRef));
    document.addEventListener('click', (event) => handleOutsideClick(event, statusFilterPopupRef));

    closePopUps();

    return () => {
      document.removeEventListener('click', (event) => handleOutsideClick(event, dateFilterPopupRef));
      document.removeEventListener('click', (event) => handleOutsideClick(event, groupFilterPopupRef));
      document.removeEventListener('click', (event) => handleOutsideClick(event, statusFilterPopupRef));
    };
  }, [dateFilterPopupRef, groupFilterPopupRef, statusFilterPopupRef]);

  return (
    <div className="flex flex-col gap-5 mb-7 w-fit">
      <h1 className="font-normal text-heading-medium">{title}</h1>
      <div className="flex flex-row flex-wrap gap-4 justify-start w-full">
        <div className="flex flex-col gap-4 sm:flex-row">
          <FinancialEventsListStatusFilter
            filters={filters}
            isOpen={openStatusFilterPopUp}
            setOpen={setOpenStatusFilterPopUp}
            ref={statusFilterPopupRef}
            setFilters={setFilters}
            setSomeFilterUsed={setSomeFilterUsed}
            closePopUps={closePopUps}
          />
          <FinancialEventsListDateFilter
            filters={filters}
            isOpen={openDateFilterPopUp}
            setOpen={setOpenDateFilterPopUp}
            ref={dateFilterPopupRef}
            setFilters={setFilters}
            setSomeFilterUsed={setSomeFilterUsed}
            closePopUps={closePopUps}
          />
          <FinancialEventsListCommercialGroupFilter
            filters={filters}
            isOpen={openGroupFilterPopUp}
            setOpen={setOpenGroupFilterPopUp}
            ref={groupFilterPopupRef}
            setFilters={setFilters}
            setSomeFilterUsed={setSomeFilterUsed}
            closePopUps={closePopUps}
          />
        </div>
        <div>
          <Button kind="ghost" label="Limpar filtros" disabled={!someFilterUsed} size="small" onClick={clearFilters} />
        </div>
      </div>
    </div>
  );
};

export default FinancialEventsFilterBar;
