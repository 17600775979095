import React, { MouseEventHandler, useState } from 'react';
import { Button } from '@clarke-energia/foton';
import { FileFormField } from '@components/atoms/form';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import useEnergyAudits from '@hooks/use-energy-audit';
import { UploadedAuditsFeedbackContent } from '@pages/energy-audit/static-data';
import { INotificationFeedbackContent } from '@utils/constants/common';

interface UploadFormProps {
  onClickToCancel: MouseEventHandler<HTMLButtonElement>;
  setNotificationResponse: (key: INotificationFeedbackContent) => void;
  setCloseModal: (key: boolean) => void;
}

interface FileToUpload {
  fileToUpload: File[];
}

const UploadForm: React.FC<UploadFormProps> = ({ setNotificationResponse, onClickToCancel, setCloseModal }) => {
  const [error, setError] = useState<string | null>(null);
  const { createEnergyAuditUploadHandler } = useEnergyAudits();
  const [submitLoading, setSubmitLoading] = React.useState<boolean>(false);

  const onSubmit: SubmitHandler<FileToUpload> = async (values) => {
    setSubmitLoading(true);
    setNotificationResponse(UploadedAuditsFeedbackContent['SCDE_FILE_PROGRESS']);
    setSubmitLoading(false);
    setCloseModal(false);
    const res = await createEnergyAuditUploadHandler(values);
    setNotificationResponse(UploadedAuditsFeedbackContent[res]);
  };

  const methods = useForm<FileToUpload>({
    defaultValues: { fileToUpload: [] },
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="flex flex-col gap-3 -mb-10">
        <FileFormField
          name="fileToUpload"
          id="signed-proposal-files"
          label={{ text: 'Tabela de consumo' }}
          options={{ required: { value: true, message: 'É preciso selecionar um arquivo para enviar' } }}
          fileSizeLimitInBytes={30 * 1020 * 1024}
          fieldDisplayOptions={{ label: 'Documentos', sublabel: 'CSV' }}
          multiple={false}
        />
        <div className="flex gap-5 justify-between mb-6 w-full">
          <Button label="Cancelar" kind={'secondary'} className="mt-5 -mb-5 w-full" onClick={onClickToCancel} />
          <Button
            loading={submitLoading}
            label="Fazer Upload"
            kind={'primary'}
            className="mt-5 -mb-5 w-full"
            type="submit"
          />
          <span className="col-span-4 pt-5 text-center rounded-md focus:outline-none text-danger-60 border-danger-30 sm:text-paragraph-medium focus:border-danger-30 focus:ring-danger-60">
            {error}
          </span>
        </div>
      </form>
    </FormProvider>
  );
};

export default UploadForm;
