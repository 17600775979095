import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { ButtonIcon, ColumnOptions, FeedbackNotification, Layout, Modal } from '@clarke-energia/foton';

import { trackEvent } from '@services/eventTracking';
import { eventLabels } from '@services/eventTracking/eventsLabels';

import { CommercialGroupNotificationFeedbackContents, INotificationFeedbackContent } from '@utils/constants/common';
import { formatCNPJ, formatCPF } from '@utils/text';
import { CommercialGroup, GroupDoc, GroupFormPayload } from '@contexts/commercial-group/types';
import useCommercialGroups from '@hooks/use-commercial-group';

import { CommercialGroupsTable } from '@components/molecules/commercial-group/table';
import FormCreateCommercialGroup from '@components/molecules/form/form-create-commercial-group';
import FormConfirmation from '@components/molecules/form/form-confirmation';
import FormGroupSkeleton from '@components/molecules/form/form-create-commercial-group/skeleton';

type renderFunction = ColumnOptions<CommercialGroup>['renderAsElement'];

const renderGroupName: renderFunction = (entry) => (
  <Link to={`/cliente/${entry.id}`} className="text-blue-500 underline">
    {entry.name}
  </Link>
);
const renderLegalName: renderFunction = (entry) => entry.legalName;
const renderDocument: renderFunction = (entry) =>
  entry.docType === GroupDoc.CNPJ ? formatCNPJ(entry.docNumber) : formatCPF(entry.docNumber);

const CommercialGroupListPage: React.FC = () => {
  const {
    commercialGroups,
    getCommercialGroups,
    deleteCommercialGroupHandler,
    loading,
    getGroupDriveFolderHandler,
    loadingDriveData,
  } = useCommercialGroups();
  const [selectedCommercialGroup, setSelectedCommercialGroup] = useState<GroupFormPayload | null>(null);

  const [typedSearchTerm, setTypedSearchTerm] = useState<string>('');
  const [openCreateCommercialGroup, setOpenCreateCommercialGroup] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openNotificationFeedBack, setOpenNotificationFeedback] = React.useState(false);
  const [messageNotificationFeedBack, setMessageNotificationFeedback] = React.useState<INotificationFeedbackContent>();

  const commercialGroupsTableColumns: ColumnOptions<CommercialGroup>[] = [
    { accessor: 'name', header: 'Empresa', renderAsElement: renderGroupName },
    { accessor: 'legalName', header: 'Razão Social', renderAsElement: renderLegalName },
    { accessor: 'docNumber', header: 'Documento', renderAsElement: renderDocument },
    {
      accessor: null,
      header: '',
      renderAsElement: (entry) => {
        return (
          <ButtonIcon
            kind={'ghost'}
            icon={'PencilIcon'}
            onClick={async () => {
              setOpenCreateCommercialGroup(true);
              setOpenNotificationFeedback(false);

              const driveFolder = await getGroupDriveFolderHandler(entry.id);

              setSelectedCommercialGroup({
                id: entry.id,
                docNumber: entry.docNumber,
                docType: entry.docType,
                legalName: entry.legalName,
                name: entry.name,
                type: entry.type,
                targetFolderId: driveFolder ? driveFolder?.id : '',
                folder: driveFolder ? driveFolder.targetFolderId : '',
              });
            }}
          />
        );
      },
    },
    {
      renderAsElement: (entry) => {
        return (
          <ButtonIcon
            kind={'ghost'}
            icon={'TrashIcon'}
            onClick={() => {
              setSelectedCommercialGroup({
                id: entry.id,
                docNumber: entry.docNumber,
                docType: entry.docType,
                legalName: entry.legalName,
                name: entry.name,
                type: entry.type,
                folder: '',
              });
              setOpenConfirmationModal(true);
              setOpenNotificationFeedback(false);
            }}
          />
        );
      },
    },
  ];

  const filteredCommercialGroups =
    typedSearchTerm.length > 0
      ? commercialGroups.filter((group) => group.name.toLowerCase().includes(typedSearchTerm.toLowerCase()))
      : commercialGroups;

  const setNotificationResponse = (content: INotificationFeedbackContent) => {
    setMessageNotificationFeedback(content);
    setOpenNotificationFeedback(true);
  };

  useEffect(() => {
    trackEvent(eventLabels.GROUPS_PAGE_VIEWED);
    getCommercialGroups();
  }, []);

  return (
    <Layout
      title="Clientes"
      loading={loading}
      headerButtons={[
        {
          kind: 'primary',
          label: 'Novo Cliente',
          onClick: () => {
            setSelectedCommercialGroup(null);
            setOpenCreateCommercialGroup(true);
          },
          size: 'small',
        },
      ]}
      searchBar={{
        onBlur: () => trackEvent(eventLabels.GROUPS_PAGE_SEARCH_GROUP),
        onTypedTermChangedCallback: (term) => setTypedSearchTerm(term),
        placeholder: 'Filtrar por nome da empresa',
      }}
      className="relative"
      darkerBackground
    >
      <div className="absolute right-0 bottom-0 my-6 mx-6 max-w-full z-100 lg:w-fit">
        {messageNotificationFeedBack && openNotificationFeedBack && (
          <FeedbackNotification
            label={messageNotificationFeedBack.label}
            message={messageNotificationFeedBack.message}
            kind={messageNotificationFeedBack.kind}
            onCloseNotification={() => setOpenNotificationFeedback(false)}
          />
        )}
      </div>
      <div className="col-span-full h-full">
        <CommercialGroupsTable
          data={filteredCommercialGroups}
          isLoading={loading}
          columnDefinitions={commercialGroupsTableColumns}
        />
      </div>
      <Modal
        open={openCreateCommercialGroup}
        setOpen={() => setOpenCreateCommercialGroup(!openCreateCommercialGroup)}
        title={`${selectedCommercialGroup ? 'Atualizar' : 'Criar'} cliente`}
        description={`Formulário de ${selectedCommercialGroup ? 'atualização' : 'criação'} de cliente`}
      >
        {loadingDriveData ? (
          <FormGroupSkeleton />
        ) : (
          <FormCreateCommercialGroup
            dataToUpdate={selectedCommercialGroup}
            setOpenCreateCommercialGroup={setOpenCreateCommercialGroup}
            onFormSuccess={() => {
              getCommercialGroups();
              setOpenCreateCommercialGroup(false);
              setNotificationResponse(
                CommercialGroupNotificationFeedbackContents[
                  selectedCommercialGroup ? 'UPDATING_COMMERCIAL_GROUP_SUCCESS' : 'CREATING_COMMERCIAL_GROUP_SUCCESS'
                ],
              );
              trackEvent(
                selectedCommercialGroup
                  ? eventLabels.UPDATE_COMMERCIAL_GROUP_BUTTON_CLICKED
                  : eventLabels.CREATE_COMMERCIAL_GROUP_BUTTON_CLICKED,
              );
            }}
            onFormFail={(driveInfoError: boolean) => {
              setNotificationResponse(
                CommercialGroupNotificationFeedbackContents[
                  selectedCommercialGroup && !driveInfoError
                    ? 'UPDATING_COMMERCIAL_GROUP_ERROR'
                    : 'CREATING_COMMERCIAL_GROUP_ERROR'
                ],
              );
              setNotificationResponse(
                CommercialGroupNotificationFeedbackContents[
                  selectedCommercialGroup && driveInfoError
                    ? 'UPDATING_DRIVE_COMMERCIAL_GROUP_ERROR'
                    : 'CREATING_DRIVE_COMMERCIAL_GROUP_ERROR'
                ],
              );
            }}
          />
        )}
      </Modal>
      <Modal
        open={openConfirmationModal}
        setOpen={() => setOpenConfirmationModal(!openConfirmationModal)}
        title="Deletar cliente"
        description="Tem certeza que deseja excluir esse cliente?"
      >
        <FormConfirmation
          onConfirmation={() => {
            selectedCommercialGroup &&
              deleteCommercialGroupHandler(selectedCommercialGroup.id, moment().format('DD/MM/YYYY'))
                .then(() => {
                  getCommercialGroups();
                  setOpenConfirmationModal(false);
                  setNotificationResponse(
                    CommercialGroupNotificationFeedbackContents.DELETING_COMMERCIAL_GROUP_SUCCESS,
                  );
                  trackEvent(eventLabels.DELETE_COMMERCIAL_GROUP_BUTTON_CLICKED);
                })
                .catch(() => {
                  setOpenConfirmationModal(false);
                  setNotificationResponse(CommercialGroupNotificationFeedbackContents.DELETING_COMMERCIAL_GROUP_ERROR);
                });
          }}
          onCancel={() => setOpenConfirmationModal(false)}
        />
      </Modal>
    </Layout>
  );
};

export default CommercialGroupListPage;
