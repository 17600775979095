import { useContextSelector } from 'use-context-selector';
import { CommercialGroupContext, CommercialGroupContextType } from '@contexts/commercial-group';

const useCommercialGroups = (): CommercialGroupContextType => {
  const getCommercialGroups = useContextSelector(CommercialGroupContext, (groups) => groups.getCommercialGroups);
  const getGroupDriveFolderHandler = useContextSelector(
    CommercialGroupContext,
    (groups) => groups.getGroupDriveFolderHandler,
  );
  const createCommercialGroupHandler = useContextSelector(
    CommercialGroupContext,
    (groups) => groups.createCommercialGroupHandler,
  );
  const updateCommercialGroupHandler = useContextSelector(
    CommercialGroupContext,
    (groups) => groups.updateCommercialGroupHandler,
  );
  const upsertGroupDriveFolderHandler = useContextSelector(
    CommercialGroupContext,
    (groups) => groups.upsertGroupDriveFolderHandler,
  );
  const deleteCommercialGroupHandler = useContextSelector(
    CommercialGroupContext,
    (groups) => groups.deleteCommercialGroupHandler,
  );
  const commercialGroups = useContextSelector(CommercialGroupContext, (groups) => groups.commercialGroups);
  const loading = useContextSelector(CommercialGroupContext, (groups) => groups.loading);
  const loadingDriveData = useContextSelector(CommercialGroupContext, (groups) => groups.loadingDriveData);

  return {
    createCommercialGroupHandler,
    updateCommercialGroupHandler,
    deleteCommercialGroupHandler,
    upsertGroupDriveFolderHandler,
    getCommercialGroups,
    getGroupDriveFolderHandler,
    commercialGroups,
    loading,
    loadingDriveData,
  };
};

export default useCommercialGroups;
