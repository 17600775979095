import { gql } from '@apollo/client';

export const GET_GROUPS_DATA_QUERY = gql`
  query GetAllCommercialGroups {
    groups {
      data {
        id
        name
        legalName
        docType
        docNumber
        type
      }
    }
  }
`;

export const GET_GROUP_DRIVE_DATA_QUERY = gql`
  query GetGroupFolderInternalStorage($groupId: ID!) {
    getGroupFolderInternalStorage(groupId: $groupId) {
      id
      targetFolderId
    }
  }
`;
