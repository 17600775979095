import { GadgetContentProps } from '@clarke-energia/foton';
import { Proposal } from '@contexts/commercial-group';
import { UserInfo } from '@contexts/user/types';
import { EnergyTypeEnum } from '@contexts/unit';
import { RolesTypes } from '@utils/translators/users-roles';
import {
  formatAsMWh,
  formatAsPercent,
  formatDate,
  formatMonthAndYearDate,
  formatToCurrentMonthAndYearDate,
  formatUuid,
} from '@utils/text';
import {
  EnergyAudit,
  EnergyAuditFormated,
  EnergyAuditStatus,
  EnergyAuditUnits,
  EnergyAuditUnitsFormated,
  ScdeListMeasurement,
  ScdeListMeasurementFormatted,
  ScdeMeasurement,
} from './types';

export const formatGadgetContent = (energyAudit: EnergyAuditFormated): GadgetContentProps[] => {
  const isNotReadyToSend = energyAudit.status === EnergyAuditStatus.NOT_READY_TO_SEND;
  return [
    {
      title: 'Necessidade Total',
      value: isNotReadyToSend ? '-' : formatAsMWh(energyAudit.neededTotal as number),
    },
    {
      title: 'Total de contratos no Mercado Livre',
      value: isNotReadyToSend ? '-' : formatAsMWh(energyAudit.freeMarketContractsTotal as number),
    },
    {
      title: 'Sobra / Exposição Total',
      value: isNotReadyToSend ? '-' : formatAsMWh(energyAudit.overplusOrExposureTotal as number),
    },
  ];
};

export const finalProposal = (proposals: Proposal[]) => {
  return proposals
    .filter(({ bids }) => bids.length)
    .sort(
      (a, b) => new Date(b.createdAt ? b.createdAt : '').getTime() - new Date(a.createdAt ? a.createdAt : '').getTime(),
    )
    .map(({ bids, traders, ...rest }) => {
      const winnerBid = bids
        .sort(
          (a, b) => new Date(b.winnerAt ? b.winnerAt : '').getTime() - new Date(a.winnerAt ? a.winnerAt : '').getTime(),
        )
        .map(({ trader, ...rest }) => {
          const findTrader = traders.find(({ id, name }) => trader.id === id && !name.includes('Clarke'));

          return {
            ...rest,
            trader: {
              usersIds: findTrader ? returnUsersIds(findTrader?.users) : [],
              name: findTrader?.name || '',
            },
          };
        });
      return {
        ...rest,
        bid: winnerBid[0],
      };
    })[0];
};

const returnUsersIds = (usersIds: any[] | []) => (usersIds.length > 0 ? usersIds.map(({ id }) => id) : []);

export const filterEmailsByCategorie = (users: UserInfo[], categorie: RolesTypes) =>
  users.filter((user) => user.categoryRoles && user.categoryRoles.includes(categorie)).map((user) => user.email);

export const formatEnergyAudit = (energyAudit: EnergyAudit): EnergyAuditFormated => {
  const trader = energyAudit.energyContract.trader;

  const lowerFlexibility =
    energyAudit.energyContract.lowerFlexibility && energyAudit.energyContract.lowerFlexibility / 100;
  const upperFlexibility =
    energyAudit.energyContract.upperFlexibility && energyAudit.energyContract.upperFlexibility / 100;

  const energyType = energyAudit.energyContract?.energyType;

  return {
    id: energyAudit.id || '',
    date: formatMonthAndYearDate(energyAudit.date),
    status: energyAudit.status || '',
    neededTotal: energyAudit.neededTotal,
    freeMarketContractsTotal: energyAudit.freeMarketContractsTotal,
    overplusOrExposureTotal: energyAudit.overplusOrExposureTotal,
    notifiedAt: formatNotifiedAt(energyAudit.notifiedAt),
    numberOfConcludedMeasurements: energyAudit.numberOfConcludedMeasurements,
    totalNumberOfMeasurements: energyAudit.totalNumberOfMeasurements,
    consumptionTotal: energyAudit.consumptionTotal,
    group: {
      name: energyAudit.group.name,
      id: energyAudit.group.id,
      type: energyAudit.group.type,
    },

    energyContract: {
      rawId: energyAudit.energyContract.id,
      id: formatUuid(energyAudit.energyContract.id) || '',
      upperFlexibility: formatAsPercent(lowerFlexibility || 0),
      lowerFlexibility: formatAsPercent(upperFlexibility || 0),
      lowerFlexTotal: formatAsMWh(energyAudit.lowerFlexTotal || 0),
      upperFlexTotal: formatAsMWh(energyAudit.upperFlexTotal || 0),
      seasonality: formatAsMWh(energyAudit.seasonality || 0),
      billedFlex: formatAsMWh(energyAudit.billedFlex || 0),
      energyType: energyType ? EnergyTypeEnum[energyType as keyof typeof EnergyTypeEnum] : null,
      trader: {
        id: trader?.id,
        name: trader?.name,
        profileCcee: energyAudit.energyContract.perfilCceeTrader || '',
        idCcee: energyAudit.energyContract.idCceeTrader?.toString() || '',
      },
    },
    units: energyAudit.status && formatUnitsData(energyAudit.units, energyAudit.status),
  };
};

const formatUnitsData = (units: EnergyAuditUnits[], status: EnergyAuditStatus): EnergyAuditUnitsFormated[] => {
  const isNotReadyToSend = status === EnergyAuditStatus.NOT_READY_TO_SEND;

  return units.map(
    ({
      consumptionAmount,
      lostsAmount,
      proinfa,
      neededAmount,
      overplusOrExposureAmount,
      freeMarketContractsAmount,
      missingHours,
      unit,
    }) => ({
      unit,
      consumptionAmount: isNotReadyToSend && consumptionAmount === 0 ? '-' : formatAsMWh(consumptionAmount as number),
      lostsAmount: isNotReadyToSend && consumptionAmount === 0 ? '-' : formatAsMWh(lostsAmount as number),
      proinfa: isNotReadyToSend && consumptionAmount === 0 ? '-' : formatAsMWh(proinfa as number),
      neededAmount: isNotReadyToSend && consumptionAmount === 0 ? '-' : formatAsMWh(neededAmount as number),
      missingHours: missingHours && consumptionAmount === 0 ? missingHours : 0,
      overplusOrExposureAmount:
        isNotReadyToSend && consumptionAmount === 0 ? '-' : formatAsMWh(overplusOrExposureAmount as number),
      freeMarketContractsAmount:
        isNotReadyToSend && consumptionAmount === 0 ? '-' : formatAsMWh(freeMarketContractsAmount as number),
    }),
  );
};

const formatNotifiedAt = (date: string | undefined) => {
  if (!date) return '';

  const notifiedAtFormated = formatDate(new Date(date));
  const [dateFormated, timeFormated] = notifiedAtFormated.split(' ');

  return `Enviado ${dateFormated} às ${timeFormated}`;
};

export const formatEnergyAuditByScde = (energyAuditList: ScdeListMeasurement): ScdeListMeasurementFormatted => {
  return {
    page: energyAuditList.page,
    limit: energyAuditList.limit,
    total: energyAuditList.total,
    data: energyAuditList.data.map((measurement: ScdeMeasurement) => ({
      measuringPoint: measurement.measuringPoint,
      monthYear: formatToCurrentMonthAndYearDate(measurement.monthYear),
      status: measurement.status,
      totalConsumption: formatAsMWh(measurement.totalConsumption),
      unitname: measurement.unit.name,
      idCceeProfile: measurement.unit.cceeProfile ? measurement.unit.cceeProfile.idCcee : '-',
    })),
  };
};
