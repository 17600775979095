import { FinancialEventStatus } from '@contexts/financial-events';

export const financialEventStatusTranslator: Record<FinancialEventStatus, string> = {
  NOT_SENT: 'Não enviado',
  SENT: 'Enviado',
  SENDING_FAILED: 'Erro no envio',
  PROCESSED: 'Processado',
  PROCESSING_FAILED: 'Erro ao processar',
  UNPROCESSED: 'Não processado',
};
