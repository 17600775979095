import React, { useState } from 'react';
import { Button, SearchBar, Modal } from '@clarke-energia/foton';

import useEnergyAudits from '@hooks/use-energy-audit';
import { useNotification } from '@hooks/use-notification';

import EnergyAuditsUploadTable from './table';
import UploadForm from '../form/form-upload-to-energy-audit';
import { UploadedAuditsFeedbackContent } from '@pages/energy-audit/static-data';

interface EnergyAuditListProps {
  referenceDate: string;
}

const EnergyAuditUploadList: React.FC<EnergyAuditListProps> = ({ referenceDate }) => {
  const { getScdeListMeasurementHandler, scdeData, loading } = useEnergyAudits();
  const { setNotificationResponse, messageNotificationFeedBack } = useNotification();

  const [page, setPage] = useState<number>(1);
  const [typedSearchTerm, setTypedSearchTerm] = React.useState<string>('');
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);
  const measuringPointMinLength = 13;

  React.useEffect(() => {
    if (messageNotificationFeedBack?.label === UploadedAuditsFeedbackContent.SCDE_FILE_SUCCESS.label) {
      const params = {
        page,
        monthYear: referenceDate.slice(0, -3),
        measuringPoint: '',
      };
      getScdeListMeasurementHandler(params);
    }
  }, [messageNotificationFeedBack]);

  React.useEffect(() => {
    if (typedSearchTerm.length >= 1 && typedSearchTerm.length < measuringPointMinLength) return;
    const params = {
      page,
      monthYear: referenceDate.slice(0, -3),
      measuringPoint: typedSearchTerm,
    };
    getScdeListMeasurementHandler(params);
  }, [referenceDate, page, typedSearchTerm.length]);

  return (
    <>
      <div className="justify-between mb-6 lg:flex">
        <Button
          label="Fazer Upload"
          type="button"
          kind="primary"
          size="small"
          onClick={() => setOpenConfirmationModal(true)}
        />
        <SearchBar
          label=""
          iconPosition="right"
          placeholder="Busca por ponto de medição"
          onTypedTermChangedCallback={(term) => setTypedSearchTerm(term)}
        />
      </div>
      <EnergyAuditsUploadTable
        loading={loading}
        tableData={scdeData?.data}
        total={scdeData?.total}
        currentPage={scdeData?.page}
        itemPerpage={scdeData?.limit}
        setCurrentPage={setPage}
      />
      <Modal
        open={openConfirmationModal}
        setOpen={() => setOpenConfirmationModal(!openConfirmationModal)}
        title="Upload de consumo"
      >
        <UploadForm
          setNotificationResponse={setNotificationResponse}
          onClickToCancel={() => setOpenConfirmationModal(!openConfirmationModal)}
          setCloseModal={setOpenConfirmationModal}
        />
      </Modal>
    </>
  );
};

export default EnergyAuditUploadList;
