import * as OutlineIcons from '@heroicons/react/24/outline';

import { FeedbackNotificationProps, TagProps } from '@clarke-energia/foton';
import { EnergyAuditStatus, IEnergyAuditStatusMapper } from '@contexts/energy-audit';
import { ScdeStatus } from '@contexts/energy-audit/types';

import { ConsumptionReadingStatus, MesaurementsFeedbackType } from '@contexts/energy-audit-consumptions';

import { INotificationFeedbackContent } from '@utils/constants/common';

export type IconName = keyof typeof OutlineIcons;

export interface EnergyAuditOverflowMenuOptionsProps {
  id: string;
  label: string;
  icon: IconName;
  specificClassName: string;
  iconClassName: string;
}

export const EnergyAuditStatusTagPropsSelector: Record<keyof typeof EnergyAuditStatus, TagProps> = {
  NOT_READY_TO_SEND: {
    color: 'red',
    label: 'Medições incompletas',
    kind: 'default',
  },
  READY_TO_SEND: {
    color: 'primary',
    label: 'Pronto para envio',
    kind: 'default',
  },
  SENT: {
    color: 'green',
    label: 'Balanço enviado',
    kind: 'default',
  },
};

export const ConsumptionReadingStatusTagPropsSelector: Record<keyof typeof ConsumptionReadingStatus, TagProps> = {
  COMPLETE: {
    color: 'green',
    label: 'Completo',
    kind: 'default',
  },
  NOT_COMPLETE: {
    color: 'yellow',
    label: 'Incompleto',
    kind: 'default',
  },
};

export const energyAuditStatusPopupContent: Record<EnergyAuditStatus, IEnergyAuditStatusMapper> = {
  NOT_READY_TO_SEND: {
    id: '1',
    label: 'Balanço incompleto',
    statusType: EnergyAuditStatus.NOT_READY_TO_SEND,
  },
  READY_TO_SEND: {
    id: '2',
    label: 'Pronto para envio',
    statusType: EnergyAuditStatus.READY_TO_SEND,
  },
  SENT: {
    id: '3',
    label: 'Balanço enviado',
    statusType: EnergyAuditStatus.SENT,
  },
};

export const measurementsFeedbackContent: Record<MesaurementsFeedbackType, INotificationFeedbackContent> = {
  UPDATING_MISSING_HOURS: {
    kind: 'neutral',
    message: 'Preenchendo horas faltantes...',
    label: 'Aguarde',
  },
  MISSING_HOURS_UPDATED_FAILED: {
    kind: 'error',
    message: 'Houve um erro ao preencher horas faltantes.',
    label: 'Erro',
  },
  MISSING_HOURS_UPDATED_SUCCESS: {
    kind: 'primary',
    message: 'Horas faltantes preenchidas!',
    label: 'Sucesso',
  },
  UPDATING_DAILY_CONSUMPTIONS: {
    kind: 'neutral',
    message: 'Buscando dados na CCEE...',
    label: 'Aguarde',
  },
  DAILY_CONSUMPTIONS_UPDATED_FAILED: {
    kind: 'error',
    message: 'Houve um erro ao atualizar dados.',
    label: 'Erro',
  },
  DAILY_CONSUMPTIONS_UPDATED_SUCCESS: {
    kind: 'primary',
    message: 'Horas atualizadas!',
    label: 'Sucesso',
  },
  EXCEL_DOWNLOAD_SUCCESS: {
    kind: 'primary',
    label: 'Sucesso',
    message: 'Download efetuado.',
  },
  EXCEL_DOWNLOAD_ERROR: {
    kind: 'error',
    label: 'Erro',
    message: 'Erro ao fazer download.',
  },
};

export const generalAuditsFeedbackContent: Record<string, INotificationFeedbackContent> = {
  EXCEL_DOWNLOAD_IN_PROGRESS: {
    kind: 'neutral',
    label: 'Exportando',
    message: 'O download iniciara em breve!',
  },
  EXCEL_DOWNLOAD_SUCCESS: {
    kind: 'primary',
    label: 'Sucesso',
    message: 'Download efetuado.',
  },
  EXCEL_DOWNLOAD_ERROR: {
    kind: 'error',
    label: 'Erro',
    message: 'Houve um erro ao fazer download.',
  },
  SENDING_EMAIL: {
    kind: 'neutral',
    message: 'Enviando os emails...',
    label: 'Aguarde',
  },
  SENDING_EMAIL_FAILED: {
    kind: 'error',
    message: 'Houve um erro ao enviar os emails.',
    label: 'Erro',
  },
  SENDING_EMAIL_SUCCESS: {
    kind: 'primary',
    message: 'Emails enviados!',
    label: 'Sucesso',
  },
};

export const getPrepareAuditToBeSentFeedbackContent = (
  onCloseNotification: () => void,
): Record<string, FeedbackNotificationProps> => ({
  UPDATING: {
    kind: 'neutral',
    message: 'Enviando os emails...',
    label: 'Aguarde',
    onCloseNotification: onCloseNotification,
  },
  UPDATED_FAILED: {
    kind: 'error',
    message: 'Houve um erro ao enviar os emails.',
    label: 'Erro',
    onCloseNotification: onCloseNotification,
  },
  UPDATED_SUCCESS: {
    kind: 'primary',
    message: 'Emails enviados!',
    label: 'Sucesso',
    onCloseNotification: onCloseNotification,
  },
});

export const EnergyAuditOverflowMenuOptions: EnergyAuditOverflowMenuOptionsProps[] = [
  {
    id: '1',
    label: 'Enviar balanço',
    icon: 'PaperAirplaneIcon',
    specificClassName: 'top-0',
    iconClassName: '-rotate-90',
  },
  {
    id: '2',
    label: 'Ver balanço',
    icon: 'EyeIcon',
    specificClassName: 'top-7',
    iconClassName: '',
  },
];

export const EnergyAuditByScdeStatusTagPropsSelector: Record<keyof typeof ScdeStatus, TagProps> = {
  IN_QUEUE: {
    color: 'yellow',
    label: 'Em Progresso',
    kind: 'default',
  },
  ERROR: {
    color: 'red',
    label: 'Erro',
    kind: 'default',
  },
  PROCESSED: {
    color: 'green',
    label: 'Completo',
    kind: 'default',
  },
};

export const UploadedAuditsFeedbackContent: Record<string, INotificationFeedbackContent> = {
  SCDE_FILE_PROGRESS: {
    kind: 'neutral',
    label: 'Exportando',
    message: 'O download iniciará em breve!',
  },
  SCDE_FILE_SUCCESS: {
    kind: 'primary',
    label: 'Sucesso',
    message: 'Processamento do arquivo iniciado com sucesso',
  },
  SCDE_FILE_ERROR: {
    kind: 'error',
    label: 'Erro',
    message: 'Erro ao fazer upload do arquivo.',
  },
};
