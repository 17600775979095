import { gql } from '@apollo/client';

export const GET_UNITS_QUERY = gql`
  query Group($groupId: ID!) {
    group(id: $groupId) {
      name
      units {
        id
        name
        legalName
        submarket
        energyType
        deletedAt
        createdAt
        unitType
        tariffSubgroup
        tariffModality
        powerGenerator
        generatorCost
        contractedDemandOffPeak
        contractedDemandPeak
        contractedDemandOffPeakBeforeMigration
        contractedDemandPeakBeforeMigration
        averageInvoiceAmount
        migrationDate
        expectedMigrationDate
        totalConsumptionVolume
        group {
          id
          type
        }
        cceeProfile {
          id
          idCcee
        }
        edc {
          id
          name
        }
        history {
          id
          consumptionPeak
          consumptionOffPeak
          generator
          demandOffPeak
          demandPeak
          yearMonth
        }
        docType
        docNumber
        fullTextAddress
        postalCode
        assetCode
        state
      }
    }
  }
`;

export const GET_MEASURING_POINT_QUERY = gql`
  query Query($groupId: ID!) {
    getMeasuringPointByGroupId(groupId: $groupId) {
      id
      measuringPoint
      unitId
    }
  }
`;
