import { forwardRef } from 'react';
import { Button } from '@clarke-energia/foton';

import { FinancialEventStatus } from '@contexts/financial-events';

import { PopUpSetStatus } from './popup';
import { IFinancialEventsListFilter } from '../helper';
import { financialEventStatusTranslator } from './helper';

export const FinancialEventsListStatusFilter = forwardRef<HTMLDivElement, IFinancialEventsListFilter>(
  ({ filters, isOpen, setOpen, setFilters, setSomeFilterUsed, closePopUps }, ref) => {
    const label = filters?.status ? financialEventStatusTranslator[filters.status] : 'Status';

    const onChange = (status: string) => {
      setFilters((previous) => ({ ...previous, status: status as FinancialEventStatus }));
      setSomeFilterUsed(true);
      closePopUps();
    };

    return (
      <div className="relative" ref={ref}>
        <Button
          kind={filters?.status ? 'chip' : 'secondary'}
          label={label}
          icon={isOpen ? 'ChevronUpIcon' : 'ChevronDownIcon'}
          iconPosition="right"
          size="small"
          onClick={() => setOpen(!isOpen)}
        />
        <PopUpSetStatus show={isOpen} filters={filters} onChange={onChange} />
      </div>
    );
  },
);

FinancialEventsListStatusFilter.displayName = 'FinancialEventsListStatusFilter';
