import { Button } from '@clarke-energia/foton';
import { forwardRef, useEffect, useMemo, useState } from 'react';

import useCommercialGroups from '@hooks/use-commercial-group';

import { capitalize } from '@utils/text';

import { IFinancialEventsListFilter } from '../helper';
import { PopUpSetCommercialGroup } from './popup';

interface GroupFilterElementsProps {
  onClick: (item: any) => void;
  name: string;
  id: string;
}

export const FinancialEventsListCommercialGroupFilter = forwardRef<HTMLDivElement, IFinancialEventsListFilter>(
  ({ filters, isOpen, setOpen, setFilters, setSomeFilterUsed, closePopUps }, ref) => {
    const { commercialGroups, getCommercialGroups } = useCommercialGroups();
    const [groupFilterElements, setGroupFilterElements] = useState<GroupFilterElementsProps[]>();

    const generalLabelParser = (label: string) => capitalize(label?.replaceAll('_', ' '));

    const createGroupFilterElements = () => {
      const elements = commercialGroups?.map((group) => ({
        name: generalLabelParser(group.name),
        id: group.id,
        onClick: () => {
          setOpen(false);
          setSomeFilterUsed(true);
        },
      }));

      setGroupFilterElements(elements);
    };

    useMemo(() => {
      createGroupFilterElements();
    }, [isOpen]);

    useEffect(() => {
      getCommercialGroups();
    }, []);

    const onChange = (option?: any) => {
      setOpen(false);
      setFilters((prev) => ({ ...prev, group: { id: option.value, name: option.label, cnpj: '' } }));
      setSomeFilterUsed(true);
      closePopUps();
    };

    return (
      <div className="relative" ref={ref}>
        <Button
          kind={filters?.group?.name ? 'chip' : 'secondary'}
          label={
            filters?.group?.name ? generalLabelParser(filters?.group?.name) ?? 'Grupo comercial' : 'Grupo comercial'
          }
          icon={isOpen ? 'ChevronUpIcon' : 'ChevronDownIcon'}
          iconPosition="right"
          size="small"
          onClick={() => setOpen(!isOpen)}
        />
        {commercialGroups && (
          <PopUpSetCommercialGroup onChange={onChange} groupFilterElements={groupFilterElements} show={isOpen} />
        )}
      </div>
    );
  },
);

FinancialEventsListCommercialGroupFilter.displayName = 'FinancialEventsListCommercialGroupFilter';
