import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { Layout, Tabs, FeedbackNotification, PopUpList } from '@clarke-energia/foton';

import { HOME_PATH } from '@routers/constants';

import EnergyAuditReportsProvider from '@contexts/energy-audit-report';
import EnergyAuditsProvider from '@contexts/energy-audit';
import useCommercialGroups from '@hooks/use-commercial-group';
import { useNotification } from '@hooks/use-notification';
import { formatDateFromString } from '@utils/dayjs';

import EnergyAuditList from '@components/molecules/energy-audit/lists';
import EnergyAuditReportList from '@components/molecules/energy-audit-report/list';
import EnergyAuditUploadList from '@components/molecules/energy-audit-upload/list';
import GroupUsersProvider from '@contexts/user-group';
import TraderUsersProvider from '@contexts/user-trader';

interface InitialPeriods {
  onClick: () => void;
  label: string;
  value: string;
}

const EnergyAuditListPage: React.FC = () => {
  const { openNotificationFeedBack, setOpenNotificationFeedback, messageNotificationFeedBack } = useNotification();
  const { commercialGroups, getCommercialGroups } = useCommercialGroups();

  const [activeTab, setActiveTab] = useState<'AUDIT' | 'REPORT'>('AUDIT');
  const [openPeriodSelector, setOpenPeriodSelector] = useState<boolean>(false);
  const [period, setPeriod] = useState<Record<'label' | 'value', string>>({
    label: '',
    value: '',
  });

  const buildInitialPeriods = (initialDateStr: string): Array<InitialPeriods> => {
    const defaultFormat = 'YYYY-MM-DD';
    const monthYearNameFormat = 'MMMM YYYY';
    const startDate = dayjs(initialDateStr);
    const endDate = dayjs();

    return Array.from({ length: endDate.diff(startDate, 'month') + 1 }, (_, index) => {
      const date = startDate.add(index, 'month');
      const dateStr = date.format(defaultFormat);
      const label = formatDateFromString(dateStr, defaultFormat, monthYearNameFormat);
      const formatedLabel = `${label.charAt(0).toUpperCase()}${label.slice(1)}`;
      return {
        label: formatedLabel,
        value: dateStr,
        onClick: () => {
          setOpenPeriodSelector(false);
          setPeriod({ label: formatedLabel, value: dateStr });
        },
      };
    }).reverse();
  };

  const periods = activeTab === 'AUDIT' ? buildInitialPeriods('2022-10-01') : buildInitialPeriods('2023-07-01');

  useEffect(() => {
    getCommercialGroups();

    setPeriod({
      label: periods[1].label,
      value: periods[1].value,
    });
  }, []);

  return (
    <Layout
      title="Balanço Energético"
      navigationHistoryLinks={[
        {
          label: 'Início',
          url: HOME_PATH,
        },
        {
          label: 'Balanço Energético',
          url: '#',
        },
      ]}
      headerButtons={[
        {
          icon: openPeriodSelector ? 'ChevronUpIcon' : 'ChevronDownIcon',
          iconPosition: 'right',
          kind: 'secondary',
          label: period.label,
          size: 'small',
          onClick: () => setOpenPeriodSelector(!openPeriodSelector),
        },
      ]}
      className="relative"
      darkerBackground
    >
      <div className="col-span-full mb-8 h-full">
        <PopUpList
          items={periods}
          show={openPeriodSelector}
          className="absolute mr-8 ml-7 -mt-8 sm:right-0 md:top-32 sm:top-42"
          style={{ cursor: 'pointer' }}
        />
        <Tabs
          onClick={(e) => {
            const targetElement = e.target as HTMLElement;
            if (targetElement.textContent === 'Relatório') {
              setActiveTab('REPORT');
            } else {
              setActiveTab('AUDIT');
            }
          }}
          tabs={[
            {
              label: 'Balanço Energético',
              children: (
                <GroupUsersProvider>
                  <TraderUsersProvider>
                    <EnergyAuditsProvider>
                      <EnergyAuditList referenceDate={period.value} groups={commercialGroups} />
                    </EnergyAuditsProvider>
                  </TraderUsersProvider>
                </GroupUsersProvider>
              ),
            },
            {
              label: 'Relatório',
              children: (
                <EnergyAuditReportsProvider>
                  <EnergyAuditReportList referenceDate={period.value} groups={commercialGroups} />
                </EnergyAuditReportsProvider>
              ),
            },
            {
              label: 'Upload de consumo',
              children: (
                <EnergyAuditsProvider>
                  <EnergyAuditUploadList referenceDate={period.value} />
                </EnergyAuditsProvider>
              ),
            },
          ]}
        />
        {openNotificationFeedBack && messageNotificationFeedBack ? (
          <FeedbackNotification
            className="fixed bottom-0 right-6 z-20 my-6 mx-6 min-w-[35rem] lg:w-fit"
            label={messageNotificationFeedBack?.label}
            message={messageNotificationFeedBack?.message}
            kind={messageNotificationFeedBack?.kind}
            onCloseNotification={() => setOpenNotificationFeedback(false)}
          />
        ) : null}
      </div>
    </Layout>
  );
};

export default EnergyAuditListPage;
