import {
  AllEnergyAuditsGraphQLData,
  EnergyAudit,
  EnergyAuditByIdGraphQLData,
  EnergyAuditFormated,
  EnergyAuditByEnergyContractIdStatusAndDateGraphQLData,
  EnergyAuditBySCDEGraphQLData,
  ScdeListMeasurement,
  ScdeListMeasurementFormatted,
} from './types';
import { formatEnergyAudit, formatEnergyAuditByScde } from './parser';

export class AllEnergyAuditManager {
  rawData: AllEnergyAuditsGraphQLData;
  allEnergyAudit: Array<EnergyAudit>;

  constructor(rawData: AllEnergyAuditsGraphQLData) {
    this.rawData = rawData;
    this.allEnergyAudit = rawData.getAllEnergyAudits.data;
  }

  energyAuditsParsed(): EnergyAuditFormated[] {
    return this.allEnergyAudit.map((energyAudit) => formatEnergyAudit(energyAudit));
  }
}

export class EnergyAuditByIdManager {
  rawData: EnergyAuditByIdGraphQLData;
  EnergyAudit: EnergyAudit;

  constructor(rawData: EnergyAuditByIdGraphQLData) {
    this.rawData = rawData;
    this.EnergyAudit = rawData.energyAudit;
  }

  energyAuditParsed(): EnergyAuditFormated {
    return formatEnergyAudit(this.EnergyAudit);
  }
}

export class DetailEnergyAuditFiltersManager {
  rawData: EnergyAuditByEnergyContractIdStatusAndDateGraphQLData;
  EnergyAudit: EnergyAudit;

  constructor(rawData: EnergyAuditByEnergyContractIdStatusAndDateGraphQLData) {
    this.rawData = rawData;
    this.EnergyAudit = rawData.energyAuditByEnergyContractStatusAndDate;
  }

  energyAuditParsed(): EnergyAuditFormated {
    return formatEnergyAudit(this.EnergyAudit);
  }
}

export class EnergyAuditByScde {
  rawData: EnergyAuditBySCDEGraphQLData;
  getScdeMeasurements: ScdeListMeasurement;

  constructor(rawData: EnergyAuditBySCDEGraphQLData) {
    this.rawData = rawData;
    this.getScdeMeasurements = rawData.getScdeMeasurements;
  }

  energyAuditByScdeParsed(): ScdeListMeasurementFormatted {
    return formatEnergyAuditByScde(this.getScdeMeasurements);
  }
}
