import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { ColumnOptions, Pagination, Table, Tag } from '@clarke-energia/foton';

import { ScdeMeasurementFormatted } from '@contexts/energy-audit';
import { EnergyAuditByScdeStatusTagPropsSelector } from '@pages/energy-audit/static-data';

const TableSkeleton = (): JSX.Element => {
  return (
    <>
      {Array(3)
        .fill({ w: '100%', h: '100%' })
        .map((skeleton, index) => (
          <Skeleton containerClassName="w-full" key={index} width={skeleton.w} height={skeleton.h} />
        ))}
    </>
  );
};

interface IEnergyAuditsUploadTable {
  tableData: ScdeMeasurementFormatted[];
  loading: boolean;
  total: number;
  itemPerpage?: number;
  currentPage?: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

const EnergyAuditsUploadTable = ({
  tableData,
  loading,
  total,
  itemPerpage,
  currentPage,
  setCurrentPage,
}: IEnergyAuditsUploadTable): JSX.Element => {
  const scdeColums: Array<ColumnOptions<ScdeMeasurementFormatted>> = [
    {
      accessor: 'unitname',
      header: 'Unidade Consumidora',
      renderAsElement: (entry) => entry.unitname,
    },
    {
      accessor: 'monthYear',
      header: 'Mês/Ano',
      renderAsElement: (entry) => entry.monthYear,
    },
    {
      accessor: 'measuringPoint',
      header: 'Ponto de medição',
      renderAsElement: (entry) => entry.measuringPoint,
    },
    {
      accessor: 'idCceeProfile',
      header: 'ID Perfil CCEE',
      fixedWidthInRem: 10,
      ellipsisStyle: { position: 'left-[5rem] md:left-[2rem] top-[-1rem]' },
      renderAsElement: (entry) => entry.idCceeProfile,
    },
    {
      accessor: 'totalConsumption',
      header: 'Consumo Total',
      renderAsElement: (entry) => `${entry.totalConsumption}`,
    },
    {
      accessor: 'status',
      header: 'Status de Processamento',
      sortableColumn: true,
      renderAsElement: (entry) => {
        const tagProps = entry.status
          ? EnergyAuditByScdeStatusTagPropsSelector[entry.status]
          : EnergyAuditByScdeStatusTagPropsSelector['IN_QUEUE'];
        return <Tag color={tagProps.color} kind={'default'} label={tagProps.label} />;
      },
    },
  ];

  if (loading) return <TableSkeleton />;

  return (
    <div className="flex flex-col space-y-10">
      <div className="overflow-x-visible">
        <div>
          <Table<ScdeMeasurementFormatted> className="border-top" data={tableData} tableColumns={scdeColums} />
          {total >= 10 && (
            <Pagination
              totalRows={total as number}
              rowsByPage={itemPerpage as number}
              currentPage={currentPage as number}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default EnergyAuditsUploadTable;
