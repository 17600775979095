import React, { useState, useEffect } from 'react';
import { Moment } from 'moment';
import { createContext } from 'use-context-selector';
import { useLazyQuery, useMutation } from '@apollo/client';
import { PopUpListProps } from '@clarke-energia/foton';
import { createEnergyAuditUpload } from '@services/ccee-api';

import {
  AllEnergyAuditManager,
  EnergyAuditByIdManager,
  DetailEnergyAuditFiltersManager,
  EnergyAuditByScde,
} from './manager';
import {
  GET_GROUP_ENERGY_AUDITS_QUERY,
  GET_ALL_ENERGY_AUDITS_QUERY,
  GET_ENERGY_AUDIT_BY_ID_QUERY,
  GET_EMAIL_DATA_ENERGY_AUDIT_QUERY,
  GET_ENERGY_AUDIT_BY_ENERGY_CONTRACT_ID_STATUS_AND_DATE_QUERY,
  GET_SCDE_MEASUREMENT,
} from './queries';
import {
  EnergyAuditFormated,
  EnergyAuditFilters,
  EnergyAuditsContextType,
  PrepareEnergyAuditToBeSent,
  EnergyAuditStatus,
  NotifyByEmailStatusType,
  DetailEnergyAuditFilters,
  AllEnergyAudits,
  ScdeMeasurementFilter,
  ScdeListMeasurementFormatted,
  FileToUpload,
} from './types';
import { MUTATION_PREPARE_ENERGY_AUDIT_TO_BE_SENT } from './mutations';
import { formatMonthAndYearDate } from '@utils/text';
import { useAuth } from '@src/ApolloWrapper';

export * from './types';

export const EnergyAuditsContext = createContext({} as EnergyAuditsContextType);

interface Provider {
  children: React.ReactNode;
}

const EnergyAuditsProvider: React.FC<Provider> = ({ children }: Provider) => {
  const {
    authStatus: { accessToken },
  } = useAuth();
  const [energyAudits, setEnergyAudits] = useState<AllEnergyAudits['getAllEnergyAudits']>({
    total: 0,
    page: 0,
    limit: 0,
    data: [],
  });
  const [energyAuditById, setEnergyAuditById] = useState<EnergyAuditFormated>();
  const [energyAuditId, setEnergyAuditId] = useState<string | undefined>('');
  const [openNotificationFeedBack, setOpenNotificationFeedBack] = useState<boolean>(false);
  const [openNotificationFeedBackSync, setOpenNotificationFeedBackSync] = useState<boolean>(false);
  const [lastSync, setLastSync] = useState<Moment | undefined>();
  const [openPeriodSelector, setOpenPeriodSelector] = useState<boolean>(false);
  const [energyAuditsPeriods, setEnergyAuditsPeriods] = useState<PopUpListProps['items']>();
  const [selectedEnergyAudit, setSelectedEnergyAudit] = useState<EnergyAuditFormated | null>(null);
  const [rawDate, setRawDate] = useState<string>('');
  const [emailNotificaitonStatus, setEmailNotificaitonStatus] = useState<NotifyByEmailStatusType>('NOT_STARTED');
  const [groupPeriods, setGroupPeriods] = useState<Record<string, string>[]>([]);
  const [scdeData, setScdeData] = useState<ScdeListMeasurementFormatted>({
    total: 0,
    page: 0,
    limit: 0,
    data: [],
  });

  const [
    getAllEnergyAudits,
    { loading: loadingGetAllEnergyAudits, startPolling: startListPooling, stopPolling: stopListPooling },
  ] = useLazyQuery<AllEnergyAudits>(GET_ALL_ENERGY_AUDITS_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getAllEnergyAudits }) => {
      setEnergyAudits({ ...getAllEnergyAudits });
    },
    onError: () => {
      setEnergyAudits({
        total: 0,
        page: 0,
        limit: 0,
        data: [],
      });
    },
  });

  const [getEmailData, { loading: loadingEmailData }] = useLazyQuery(GET_EMAIL_DATA_ENERGY_AUDIT_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const manager = new EnergyAuditByIdManager(data);
      const parsedData = manager.energyAuditParsed();
      setSelectedEnergyAudit(parsedData);
    },
    onError: () => {
      setSelectedEnergyAudit({} as EnergyAuditFormated);
    },
  });

  const [getGroupDatesEnergyAudit] = useLazyQuery(GET_GROUP_ENERGY_AUDITS_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const manager = new AllEnergyAuditManager(data);
      const groupAudits = manager.rawData.getAllEnergyAudits.data.map(({ date, status, energyContract }) => ({
        label: formatMonthAndYearDate(date),
        value: date,
        status: status,
        contractId: energyContract.id,
      }));

      setGroupPeriods(groupAudits);
    },
    onError: () => {
      setGroupPeriods([]);
    },
    variables: {
      page: 0,
      groupId: energyAuditById?.group?.id || '',
    },
  });

  const [getEnergyAuditById, { loading: loadingGetEnergyAuditById, startPolling, stopPolling }] = useLazyQuery(
    GET_ENERGY_AUDIT_BY_ID_QUERY,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        const manager = new EnergyAuditByIdManager(data);
        const parsedData = manager.energyAuditParsed();
        setRawDate(manager.EnergyAudit.date);
        setEnergyAuditById(parsedData);
      },
      onError: () => {
        setEnergyAuditById({} as EnergyAuditFormated);
      },
      variables: {
        energyAuditId,
      },
    },
  );

  const [getEnergyAuditByEnergyContractIdStatusAndDate, { loading: loadingGetEnergyAudit }] = useLazyQuery(
    GET_ENERGY_AUDIT_BY_ENERGY_CONTRACT_ID_STATUS_AND_DATE_QUERY,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const manager = new DetailEnergyAuditFiltersManager(data);

        const parsedData = manager.energyAuditParsed();
        setEnergyAuditById(parsedData);
        setRawDate(manager.EnergyAudit.date);
      },
      onError: () => {
        setEnergyAuditId(energyAuditById?.id);
        setEnergyAuditById({} as EnergyAuditFormated);
      },
    },
  );

  const [prepareEnergyAuditToBeSent] = useMutation(MUTATION_PREPARE_ENERGY_AUDIT_TO_BE_SENT, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      return true;
    },
    onError: () => {
      return false;
    },
  });

  const [listEnergyAuditByScde, { loading: loadingGetEnergyAuditByScde }] = useLazyQuery(GET_SCDE_MEASUREMENT, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const manager = new EnergyAuditByScde(data);
      const parsedData = manager.energyAuditByScdeParsed();
      setScdeData(parsedData);
    },
    onError: () => {
      setScdeData({
        total: 0,
        page: 0,
        limit: 0,
        data: [],
      });
    },
  });

  const getAllEnergyAuditsHandler = (params: EnergyAuditFilters) => {
    return getAllEnergyAudits({ variables: { ...params } });
  };

  const getEmailDataHandler = (energyAuditId: string) => {
    return getEmailData({ variables: { energyAuditId } });
  };

  const getEnergyAuditByIdHandler = () => {
    return getEnergyAuditById();
  };

  const getEnergyAuditByEnergyContractIdStatusAndDateHandler = (filters: DetailEnergyAuditFilters) => {
    return getEnergyAuditByEnergyContractIdStatusAndDate({
      variables: filters,
    });
  };

  const getScdeListMeasurementHandler = (filters: ScdeMeasurementFilter) => {
    return listEnergyAuditByScde({
      variables: filters,
    });
  };

  async function createEnergyAuditUploadHandler(data: FileToUpload) {
    try {
      await createEnergyAuditUpload(data, accessToken);
      return 'SCDE_FILE_SUCCESS';
    } catch (error) {
      return 'SCDE_FILE_ERROR';
    }
  }
  useEffect(() => {
    if (energyAuditId) {
      getEnergyAuditByIdHandler();
      getGroupDatesEnergyAudit();
    }
  }, [energyAuditId]);

  const energyAuditToNotify =
    energyAudits.data.length && selectedEnergyAudit
      ? energyAudits.data.filter(({ id }) => selectedEnergyAudit.id === id)[0]
      : null;

  const prepareEnergyAuditToBeSentHandler = (payload: PrepareEnergyAuditToBeSent) => {
    if (!payload.groupEmails.length && !payload.trader.emails.length) return false;

    setEmailNotificaitonStatus('IN_PROGRESS');

    return prepareEnergyAuditToBeSent({
      variables: {
        input: payload,
      },
    });
  };

  useEffect(() => {
    const energyAudit = energyAuditById?.status || energyAuditToNotify?.status;
    if (energyAudit === EnergyAuditStatus.SENT) setEmailNotificaitonStatus('DONE');
  }, [energyAuditById?.status, energyAuditToNotify?.status]);

  useEffect(() => {
    const energyAudit = energyAuditById?.status || energyAuditToNotify?.status;
    if (emailNotificaitonStatus === 'DONE' && energyAudit === EnergyAuditStatus.SENT) {
      stopPolling();
      stopListPooling();
    }

    if (emailNotificaitonStatus === 'IN_PROGRESS' && energyAuditById?.status === EnergyAuditStatus.READY_TO_SEND) {
      startPolling(5000);
    }

    if (emailNotificaitonStatus === 'IN_PROGRESS' && energyAuditToNotify?.status === EnergyAuditStatus.READY_TO_SEND) {
      startListPooling(5000);
    }
  }, [emailNotificaitonStatus]);

  return (
    <EnergyAuditsContext.Provider
      value={{
        energyAudits,
        getAllEnergyAuditsHandler,
        lastSync,
        getEnergyAuditByIdHandler,
        setEnergyAuditId,
        energyAuditById,
        openNotificationFeedBack,
        setOpenNotificationFeedBack,
        openNotificationFeedBackSync,
        setOpenNotificationFeedBackSync,
        loading:
          loadingGetAllEnergyAudits ||
          loadingGetEnergyAuditById ||
          loadingGetEnergyAudit ||
          loadingGetEnergyAuditByScde,
        loadingEmailData,
        prepareEnergyAuditToBeSentHandler,
        openPeriodSelector,
        setOpenPeriodSelector,
        energyAuditsPeriods,
        setEnergyAuditsPeriods,
        selectedEnergyAudit,
        setSelectedEnergyAudit,
        rawDate,
        getEnergyAuditByEnergyContractIdStatusAndDateHandler,
        groupPeriods,
        getEmailDataHandler,
        getScdeListMeasurementHandler,
        scdeData,
        createEnergyAuditUploadHandler,
      }}
    >
      {children}
    </EnergyAuditsContext.Provider>
  );
};

export default EnergyAuditsProvider;
