import React from 'react';
import { Select } from '@clarke-energia/foton';

import { FinancialEventsFilters, FinancialEventStatus } from '@contexts/financial-events';
import { financialEventStatusTranslator } from './helper';

export interface IPopUpList {
  filters: FinancialEventsFilters | undefined;
  show?: boolean;
  onChange: (status: string) => void;
}

export const PopUpSetStatus = ({ filters, show, onChange }: IPopUpList) => {
  return (
    <div
      className={`flex flex-col gap-2 absolute shadow-3 p-5 bg-white z-10 border-black border-[1px] rounded-medium top-9 w-[8rem] md:w-[15rem] ${
        !show && 'hidden'
      }`}
    >
      <Select
        label="Status"
        options={Object.keys(financialEventStatusTranslator).map((status, _) => ({
          display: financialEventStatusTranslator[status as FinancialEventStatus],
          value: status,
        }))}
        value={filters?.status?.toString() || 'Selecione'}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};
