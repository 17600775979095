import { Button } from '@clarke-energia/foton';
import { forwardRef } from 'react';

import { IFinancialEventsListFilter } from '../helper';
import { PopUpSetPeriod } from './popup';
import moment from 'moment';

export const FinancialEventsListDateFilter = forwardRef<HTMLDivElement, IFinancialEventsListFilter>(
  ({ filters, isOpen, setOpen, setFilters, setSomeFilterUsed, closePopUps }, ref) => {
    const onChangeMonth = (month: string) => {
      setFilters((previous) => ({ ...previous, month: parseFloat(month) }));
      setSomeFilterUsed(true);
    };

    const onChangeYear = (year: string) => {
      setFilters((previous) => ({ ...previous, year: parseFloat(year) }));
      setSomeFilterUsed(true);
      closePopUps();
    };

    const setCurrentMonthYear = () => {
      const currentDate = moment();
      setFilters({ month: currentDate.month() + 1, year: currentDate.year() });
      setSomeFilterUsed(true);
      closePopUps();
    };

    const setPreviousMonthYear = () => {
      const previousDate = moment().subtract(1, 'months');
      setFilters({ month: previousDate.month() + 1, year: previousDate.year() });
      setSomeFilterUsed(true);
      closePopUps();
    };

    return (
      <div className="relative" ref={ref}>
        <Button
          kind={filters?.month && filters?.year ? 'chip' : 'secondary'}
          label={
            filters?.month && filters?.year
              ? `${filters?.month.toString().padStart(2, '0')}/${filters?.year}`
              : 'Mês / Ano'
          }
          icon={isOpen ? 'ChevronUpIcon' : 'ChevronDownIcon'}
          iconPosition="right"
          size="small"
          onClick={() => setOpen(!isOpen)}
        />
        <PopUpSetPeriod
          show={isOpen}
          filters={filters}
          onChangeYear={onChangeYear}
          onChangeMonth={onChangeMonth}
          setCurrentMonthYear={setCurrentMonthYear}
          setPreviousMonthYear={setPreviousMonthYear}
        />
      </div>
    );
  },
);

FinancialEventsListDateFilter.displayName = 'FinancialEventsListDateFilter';
