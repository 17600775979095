import Select from 'react-select';

import { EnergyAuditStatus } from '@contexts/energy-audit';

export interface Filters {
  group?: any;
  date?: string;
  status?: EnergyAuditStatus;
  month?: number;
  year?: number;
  searchTerm?: string;
}

export interface ICommercialGroupPopUpFilter {
  groupFilterElements: any[] | undefined;
  show?: boolean;
  onChange?: (option: any) => void;
}

export const PopUpSetCommercialGroup = ({ groupFilterElements, onChange, show }: ICommercialGroupPopUpFilter) => {
  const inputOptions = groupFilterElements
    ? groupFilterElements.map(({ id, name }) => ({
        value: id,
        label: name,
      }))
    : [];

  return (
    <div
      className={`flex flex-col gap-2 shadow-3 p-5 h-fit bg-white z-10 border-black border-[1px] rounded-mediumk overflow-y-visible absolute top-9 w-[8rem] md:w-[15rem] ${
        !show && 'hidden'
      }`}
    >
      <Select
        value={''}
        placeholder={'Selecione o grupo'}
        options={inputOptions as []}
        onChange={onChange}
        maxMenuHeight={300}
        isDisabled={false}
        styles={{
          control: (base) => ({
            ...base,
            '&:hover': { borderColor: 'black' },
            border: '1px solid black',
            boxShadow: 'none',
          }),
          option: (base, state) => ({
            ...base,
            backgroundColor: state.isSelected ? '#00DF7C' : 'none',
            '&:hover': { backgroundColor: state.isSelected ? 'none' : '#F1FFEB' },
          }),
        }}
      />
    </div>
  );
};
