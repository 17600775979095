import React from 'react';
import Skeleton from 'react-loading-skeleton';

const FormGroupSkeleton = () => {
  return (
    <div className="flex flex-col gap-5 pt-4 -mb-8">
      {Array.from({ length: 5 }).map((_, index) => (
        <Skeleton key={`skeleton-field-${index}`} height={40} />
      ))}
    </div>
  );
};

export default FormGroupSkeleton;
