import { useContextSelector } from 'use-context-selector';
import { FinancialEventsContext, FinancialEventsContextType } from '@contexts/financial-events';

const useFinancialEvents = (): FinancialEventsContextType => {
  const loading = useContextSelector(FinancialEventsContext, (FinancialEvents) => FinancialEvents.loading);
  const financialEvents = useContextSelector(
    FinancialEventsContext,
    (FinancialEvents) => FinancialEvents.financialEvents,
  );
  const financialEventsBatch = useContextSelector(
    FinancialEventsContext,
    (FinancialEvents) => FinancialEvents.financialEventsBatch,
  );
  const financialEventsBatchId = useContextSelector(
    FinancialEventsContext,
    (FinancialEvents) => FinancialEvents.financialEventsBatchId,
  );
  const page = useContextSelector(FinancialEventsContext, (FinancialEvents) => FinancialEvents.page);
  const setPage = useContextSelector(FinancialEventsContext, (FinancialEvents) => FinancialEvents.setPage);
  const setTermFilter = useContextSelector(FinancialEventsContext, (FinancialEvents) => FinancialEvents.setTermFilter);
  const referencePeriodFilter = useContextSelector(
    FinancialEventsContext,
    (FinancialEvents) => FinancialEvents.referencePeriodFilter,
  );
  const setReferencePeriodFilter = useContextSelector(
    FinancialEventsContext,
    (FinancialEvents) => FinancialEvents.setReferencePeriodFilter,
  );
  const commercialGroupIdFilter = useContextSelector(
    FinancialEventsContext,
    (FinancialEvents) => FinancialEvents.commercialGroupIdFilter,
  );
  const setCommercialGroupIdFilter = useContextSelector(
    FinancialEventsContext,
    (FinancialEvents) => FinancialEvents.setCommercialGroupIdFilter,
  );
  const typeFilter = useContextSelector(FinancialEventsContext, (FinancialEvents) => FinancialEvents.typeFilter);
  const setTypeFilter = useContextSelector(FinancialEventsContext, (FinancialEvents) => FinancialEvents.setTypeFilter);
  const setStatusFilter = useContextSelector(
    FinancialEventsContext,
    (FinancialEvents) => FinancialEvents.setStatusFilter,
  );
  const setFinancialEventsBatchId = useContextSelector(
    FinancialEventsContext,
    (FinancialEvents) => FinancialEvents.setFinancialEventsBatchId,
  );
  const getFinancialEventsHandler = useContextSelector(
    FinancialEventsContext,
    (FinancialEvents) => FinancialEvents.getFinancialEventsHandler,
  );
  const getFinancialEventsBatchHandler = useContextSelector(
    FinancialEventsContext,
    (FinancialEvents) => FinancialEvents.getFinancialEventsBatchHandler,
  );
  const createFinancialEventsBatchHandler = useContextSelector(
    FinancialEventsContext,
    (FinancialEvents) => FinancialEvents.createFinancialEventsBatchHandler,
  );
  const handleSendFinancialEventsByBatchId = useContextSelector(
    FinancialEventsContext,
    (FinancialEvents) => FinancialEvents.handleSendFinancialEventsByBatchId,
  );
  const handleSendFinancialEventsByIdsList = useContextSelector(
    FinancialEventsContext,
    (FinancialEvents) => FinancialEvents.handleSendFinancialEventsByIdsList,
  );

  const refetchGetFinancialEventsBatchHandler = useContextSelector(
    FinancialEventsContext,
    (FinancialEvents) => FinancialEvents.refetchGetFinancialEventsBatchHandler,
  );
  const totalFinancialEvents = useContextSelector(
    FinancialEventsContext,
    (FinancialEvents) => FinancialEvents.totalFinancialEvents,
  );
  const sendFinancialEventsStatus = useContextSelector(
    FinancialEventsContext,
    (FinancialEvents) => FinancialEvents.sendFinancialEventsStatus,
  );
  const openNotificationFeedBack = useContextSelector(
    FinancialEventsContext,
    (FinancialEvents) => FinancialEvents.openNotificationFeedBack,
  );
  const setOpenNotificationFeedBack = useContextSelector(
    FinancialEventsContext,
    (FinancialEvents) => FinancialEvents.setOpenNotificationFeedBack,
  );
  const getFinancialEventsBatchForcedHandler = useContextSelector(
    FinancialEventsContext,
    (FinancialEvents) => FinancialEvents.getFinancialEventsBatchForcedHandler,
  );
  const updateFinancialEventAttachmentHandler = useContextSelector(
    FinancialEventsContext,
    (FinancialEvents) => FinancialEvents.updateFinancialEventAttachmentHandler,
  );
  const updateFinancialEventTargetUsersHandler = useContextSelector(
    FinancialEventsContext,
    (FinancialEvents) => FinancialEvents.updateFinancialEventTargetUsersHandler,
  );

  return {
    getFinancialEventsBatchForcedHandler,
    financialEvents,
    financialEventsBatch,
    financialEventsBatchId,
    loading,
    setPage,
    page,
    setTermFilter,
    referencePeriodFilter,
    setReferencePeriodFilter,
    commercialGroupIdFilter,
    setCommercialGroupIdFilter,
    typeFilter,
    setTypeFilter,
    setStatusFilter,
    setFinancialEventsBatchId,
    getFinancialEventsHandler,
    getFinancialEventsBatchHandler,
    createFinancialEventsBatchHandler,
    handleSendFinancialEventsByBatchId,
    handleSendFinancialEventsByIdsList,
    refetchGetFinancialEventsBatchHandler,
    updateFinancialEventAttachmentHandler,
    updateFinancialEventTargetUsersHandler,
    totalFinancialEvents,
    sendFinancialEventsStatus,
    openNotificationFeedBack,
    setOpenNotificationFeedBack,
  };
};

export default useFinancialEvents;
