import React, { useEffect, useState, useCallback } from 'react';
import _ from 'lodash';

import { FinancialEventsFilters } from '@contexts/financial-events/types';
import useFinancialEvents from '@hooks/use-financial-events';
import { capitalize } from '@utils/text';

import { IFinancialEventsTab, financialEventsTabs } from '@pages/financial-events/static-data';
import FinancialEventsFilterBar from '@components/molecules/financial-events/list/financial-events-filter-bar';
import FinancialEventsTable from '@components/molecules/financial-events/list/financial-events-table';
import FinancialEventTypeTab from '@components/atoms/financial-events/list/type-tab';

interface IFinancialEventsListCore {
  filters?: FinancialEventsFilters;
  setFilters: React.Dispatch<React.SetStateAction<FinancialEventsFilters | undefined>>;
}

const FinancialEventsListCore = ({ filters, setFilters }: IFinancialEventsListCore) => {
  const [financialEventsTab, setFinancialEventsTab] = useState<IFinancialEventsTab>(financialEventsTabs[0]);
  const [someFilterUsed, setSomeFilterUsed] = useState(false);
  const [previousFilters, setPreviousFilters] = useState(filters);

  const {
    financialEvents,
    loading,
    setOpenNotificationFeedBack,
    setPage,
    page,
    totalFinancialEvents,
    setTermFilter,
    setTypeFilter,
    setReferencePeriodFilter,
    setCommercialGroupIdFilter,
    setStatusFilter,
  } = useFinancialEvents();

  const handleTabChange = useCallback(
    (item: IFinancialEventsTab) => {
      setFinancialEventsTab(item);
      setSomeFilterUsed(false);
      setFilters(undefined);
    },
    [setFilters],
  );

  useEffect(() => {
    if (!_.isEqual(filters, previousFilters)) {
      setPage(1);
      setPreviousFilters(filters);
    }
  }, [filters, previousFilters, setPage]);

  useEffect(() => {
    setReferencePeriodFilter(
      filters?.month && filters?.year ? new Date(`${filters.year}-${filters.month}-01`) : undefined,
    );
    setTermFilter(filters?.searchTerm);
    setCommercialGroupIdFilter(filters?.group?.id);
    setTypeFilter(financialEventsTab.type);
    setStatusFilter(filters?.status);
  }, [
    filters,
    financialEventsTab,
    setReferencePeriodFilter,
    setTermFilter,
    setCommercialGroupIdFilter,
    setTypeFilter,
    setStatusFilter,
  ]);

  return (
    <div className="col-span-full mb-8 h-full">
      <div className="flex">
        {financialEventsTabs.map((item) => (
          <FinancialEventTypeTab
            key={item.id}
            label={item.title === 'EER' ? item.title : capitalize(item.title)}
            isActive={financialEventsTab.type === item.type}
            disabled={item.disabled}
            onClick={() => handleTabChange(item)}
          />
        ))}
      </div>

      <FinancialEventsFilterBar
        title={`Aporte de ${financialEventsTab.title} emitidos`}
        filters={filters}
        setFilters={setFilters}
        someFilterUsed={someFilterUsed}
        setSomeFilterUsed={setSomeFilterUsed}
      />

      <FinancialEventsTable
        activeFinancialEventType={financialEventsTab.type}
        isFetchingFinancialEvents={loading}
        financialEventsData={financialEvents}
        totalEventsCount={totalFinancialEvents ?? 0}
        currentPage={page}
        setCurrentPage={setPage}
        eventsByPage={10}
        setOpenNotificationFeedBack={setOpenNotificationFeedBack}
      />
    </div>
  );
};

export default FinancialEventsListCore;
